
  .react-chatbot-kit-chat-container{
      width: 400px ;
      height: 330px;
  }
  
  .react-chatbot-kit-chat-bot-message{
    width: 300.5px;
  }

  .react-chatbot-kit-chat-inner-container{
    width: 100%;
    height: 100%;
  }

  .react-chatbot-kit-chat-message-container{
    width: 100% ;
    height:  80%;
  }

  .react-chatbot-kit-chat-message-container::-webkit-scrollbar{
    display: none;
  }

  /* .react-chatbot-kit-chat-btn-send{
  } */

  .maindiv{
    width: 100%;
    background-color: red;
    position: relative;
    text-align: center;
    background-color: #282c34;
  
  }
  
  .Chatbot_div{
    position: fixed;
    float: right;
    bottom:12%;
    right:1%;
    background-color: white;
    align-items: right;
    justify-content: right;
    color:#282c34 ;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #33906C;
    width: fit-content;
  }
  

  @media only screen and (min-width: 398px) and (max-width: 1024px) {
    .Chatbot_div{
     bottom: 13%;
     right: 4%; 
    }
  }
  
  