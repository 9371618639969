* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
  font-family: Nunito;
  overflow-x: hidden; /* Prevents horizontal scrolling */
}

body::-webkit-scrollbar {
  display: none;
}

input {
  border: 1px solid green;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}


.custom-time-picker {
  display: inline-block;
  font-family: Arial, sans-serif;
  font-size: 16px;
}

.picker {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  border-radius: 5px;
  padding: 5px;
}

.picker select {
  appearance: none;
  background-color: transparent;
  border: none;
  font-size: 16px;
  margin: 0;
  padding: 5px;
  width: 60px; /* Adjust width as needed */
  text-align: center;
  cursor: pointer;
}

.picker .separator {
  margin: 0 5px;
  font-weight: bold;
}

.timezone-prompt-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.timezone-prompt {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 300px; /* Adjust as needed */
  text-align: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.timezone-prompt p {
  margin-bottom: 1rem;
}

.timezone-prompt button {
  margin-right: 0.5rem;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.timezone-prompt button:first-child {
  background-color: #28a745;
  color: #fff;
}

.timezone-prompt button:last-child {
  background-color: #dc3545;
  color: #fff;
}

.timezone-prompt button:hover {
  opacity: 0.8;
}



.timezone-prompt {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.timezone-prompt button {
  margin: 0 10px;
}



/*------------------------------------------------------Chat style */
.message.local {
  text-align: right;
  background-color: #dcf8c6;
}

.message.remote {
  text-align: left;
  background-color: #fff;
}



/* src/styles/Chat.css */

.chat-popup {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 300px;
  height: 400px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  z-index: 1000;
}

.chat-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #f1f1f1;
}

.chat-messages {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
}

.chat-input {
  display: flex;
  padding: 10px;
}

.chat-input input {
  flex-grow: 1;
  margin-right: 10px;
}

.message {
  margin-bottom: 10px;
  padding: 5px 10px;
  border-radius: 10px;
}

.message.local {
  text-align: right;
  background-color: #dcf8c6;
}

.message.remote {
  text-align: left;
  background-color: #fff;
}

.chat-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1001;
}





/* *------------------------------------------------------Navbar style */


:root {
  --primary-color: #3bca8e;
  --primary-dark: #2ea97a;
  --text-color: #333333;
  --text-light: #666666;
  --bg-color: #ffffff;  /* Change this to white */
  --bg-light: #ffffff;  /* Change this to white */
  --border-color: #e0e0e0;
  --shadow-sm: 0 2px 4px rgba(0,0,0,0.04);
  --shadow-md: 0 4px 6px rgba(0,0,0,0.1);
  --shadow-lg: 0 10px 15px rgba(0,0,0,0.1);
  --transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  --font-main: 'Poppins', sans-serif;
}

body {
  font-family: var(--font-main);
}

.Navbar_main {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 5%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: var(--bg-color);
  box-shadow: var(--shadow-sm);
  transition: var(--transition);
}

.Navbar_nav_firstdiv {
  width: 48px;
  height: 48px;
  overflow: hidden;
  border-radius: 12px;
  box-shadow: var(--shadow-sm);
  transition: var(--transition);
}

.Navbar_nav_firstdiv img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: var(--transition);
  cursor: pointer;
}

.Navbar_nav_firstdiv:hover {
  box-shadow: var(--shadow-md);
  transform: translateY(-2px);
}

.Navbar_nav_div {
  display: flex;
  align-items: center;
}

.Navlink {
  padding: 0.6rem 1.2rem;
  color: var(--text-color);
  font-size: 0.95rem;
  font-weight: 500;
  text-decoration: none;
  transition: var(--transition);
  border-radius: 8px;
  margin: 0 0.4rem;
  position: relative;
  letter-spacing: 0.3px;
  cursor: pointer;
}

.Navlink::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 50%;
  width: 0;
  height: 2px;
  background-color: var(--primary-color);
  transition: var(--transition);
  transform: translateX(-50%);
}

.Navlink:hover::after,
.Navlink.active::after {
  width: 70%;
}

.Navlink:hover, 
.Navlink:focus {
  color: var(--primary-color);
  background-color: var(--bg-light);
  outline: none;
}

.Navlink.active {
  color: var(--primary-color);
  font-weight: 600;
}

.Navbar_nav_lastdiv {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

/* Shared styles for sign-in and language buttons */
.nav-btn-sign, .dropdown-toggle {
  padding: 0.6rem 1.4rem;
  font-size: 0.95rem;
  font-weight: 600;
  border-radius: 8px;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  cursor: pointer;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  background-color: var(--primary-color);
  color: var(--bg-color);
  border: none;
  box-shadow: 0 4px 0 var(--primary-dark);
  position: relative;
  top: 0;
}

.nav-btn-sign:hover,
.nav-btn-sign:focus,
.dropdown-toggle:hover,
.dropdown-toggle:focus {
  background-color: var(--primary-dark);
  color: var(--bg-color);
  box-shadow: 0 2px 0 var(--primary-dark);
  top: 2px;
}

.nav-btn-sign:active,
.dropdown-toggle:active {
  box-shadow: none;
  top: 4px;
}

/* Specific styles for language button */
.dropdown-toggle {
  padding: 0.4rem 0.8rem;
  font-size: 0.85rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.dropdown-toggle::after {
  content: '\25BC';
  margin-left: 0.3rem;
  font-size: 0.7rem;
}

.dropdown-toggle .bi-globe-central-south-asia {
  font-size: 0.9em;
  margin-right: 0.3rem !important;
}

/* Dropdown styles - Customize colors here */
.dropdown-menu {
  min-width: 200px;
  background-color: var(--bg-color); /* Dropdown background color */
  border: 1px solid var(--border-color);
  box-shadow: var(--shadow-lg);
  border-radius: 8px;
  overflow: hidden;
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.dropdown-menu.show {
  opacity: 1;
  transform: translateY(0);
}

.dropdown-item {
  padding: 0.75rem 1.2rem;
  font-size: 0.9rem;
  color: var(--text-color); /* Dropdown item text color */
  transition: var(--transition);
}

.dropdown-item:hover,
.dropdown-item:focus {
  background-color: var(--bg-light); /* Dropdown item hover background color */
  color: var(--primary-color); /* Dropdown item hover text color */
}

.menu-button {
  display: none;
  background: none;
  border: none;
  font-size: 1.5rem;
  color: var(--text-color);
  cursor: pointer;
  transition: var(--transition);
}

.menu-button:hover,
.menu-button:focus {
  color: var(--primary-color);
  outline: none;
}

.responsive-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: var(--bg-color);
  padding: 1rem;
  box-shadow: var(--shadow-lg);
  flex-direction: column;
  align-items: center;
  transition: var(--transition);
}

@media screen and (max-width: 1024px) {
  .Navbar_nav_div {
    display: none;
  }

  .menu-button {
    display: block;
  }

  .responsive-menu {
    display: flex;
  }

  .responsive-menu .Navlink {
    width: 100%;
    text-align: center;
    padding: 0.8rem;
    margin: 0.3rem 0;
  }

  .Navbar_nav_lastdiv {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .nav-btn-sign.mobile-visible,
  .dropdown-toggle.mobile-visible {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .nav-btn-sign.mobile-visible {
    padding: 0.4rem 0.8rem;
    font-size: 0.8rem;
  }

  .dropdown-toggle.mobile-visible {
    padding: 0.3rem 0.6rem;
    font-size: 0.75rem;
  }
}

@media screen and (max-width: 480px) {
  .Navbar_main {
    padding: 0.75rem 4%;
  }

  .Navbar_nav_firstdiv {
    width: 40px;
    height: 40px;
  }

  .nav-btn-sign.mobile-visible,
  .dropdown-toggle.mobile-visible {
    padding: 0.3rem 0.6rem;
    font-size: 0.75rem;
  }
}

.dropdown-toggle.btn-outline-success {
  background-color: var(--primary-color) !important;
  color: var(--bg-color) !important;
  border: none !important;
  box-shadow: 0 4px 0 var(--primary-dark) !important;
}

.dropdown-toggle.btn-outline-success:hover,
.dropdown-toggle.btn-outline-success:focus {
  background-color: var(--primary-dark) !important;
  color: var(--bg-color) !important;
  box-shadow: 0 2px 0 var(--primary-dark) !important;
}

.dropdown-toggle.btn-outline-success:active {
  box-shadow: none !important;
}


/* Accessibility */
@media (prefers-reduced-motion: reduce) {
  * {
    transition: none !important;
  }
}

/* High Contrast Mode */
@media (prefers-contrast: high) {
  :root {
    --primary-color: #0b500b;
    --text-color: #000000;
    --bg-color: #ffffff;
  }
}

/* *------------------------------------------------------Home style */

.slick-prev:before,
.slick-next:before {
  color: black !important;
}

.Home_main_upper_div {
  /* background-color: red; */
  /* padding: 20px 30px; */
}

.Home_main_upper_div_col1 {
  background-image: url("https://speakable.online/api/image/tr:n-ik_ml_thumbnail/letsChat_IFh8uqjCA.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  position: relative;
}

.Home_text_div_col1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-height: 15vmax;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Home_text_div_col1 > h1 {
  text-decoration: none solid rgb(51, 51, 51);
  font-size: 42px;
  color: white;
}

.Home_text_div_col1 > h3 {
  text-decoration: none solid rgb(51, 51, 51);
  font-size: 14px;
  color: white;
}

.Home_text_div_col1 > button {
  width: 12%;
  font-size: 14px;
  padding: 10px;
}

.Home_main_upper_div_col2 {
  background-image: url("https://speakable.online/api/image/tr:n-ik_ml_thumbnail/discover_IcK5H8Oqg.webp");
  background-size: cover;
  background-position: center;

  height: 100%;
  /* width: 100%; */

  position: relative;
}

.Home_text_div_col2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-height: 15vmax;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Home_text_div_col2 > h1 {
  text-decoration: none solid rgb(51, 51, 51);
  font-size: 42px;
  color: white;
}

.Home_text_div_col2 > h3 {
  text-decoration: none solid rgb(51, 51, 51);
  font-size: 14px;
  color: white;
  margin-top: 20px;
  margin-bottom: 40px;
  font-weight: 200;
}

.Home_text_div_col2 > button {
  width: 14%;
  font-size: 14px;
  padding: 10px;
}

.Home_main_upper_div_col3 {
  background-image: url("https://speakable.online/api/image/tr:n-ik_ml_thumbnail/courses_DCiBk3sba.webp");
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;

  position: relative;
}
.bannereDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60%;
  padding: 20% 1rem 1rem 8%;
  color: white;
  gap: 16px;
}
.Home_text_div_col3 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-height: 15vmax;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
#Home {
  padding-top: 4.5rem;
}
.Home_text_div_col3 > h1 {
  text-decoration: none solid rgb(51, 51, 51);
  font-size: 42px;
  color: white;
}
button.btn.btn-outline-light.rounded-5.banner-Btn {
  padding: 0.5rem 2rem;
}

.Home_text_div_col3 > h3 {
  text-decoration: none solid rgb(51, 51, 51);
  font-size: 14px;
  color: white;
  margin-top: 20px;
  margin-bottom: 40px;
  font-weight: 200;
}

.Home_text_div_col3 > button {
  width: 14%;
  font-size: 14px;
  padding: 10px;
}



/* ----------------------------------------------------------  courses */

.Slider_Div {
  min-width: 100%;
  min-height: 100%;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}

.course_main-Box {
  width: 100%;
  max-width: 297px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s ease;
  border: 1px solid #c1b7b7;
  padding: 10px;
  border-radius: 13px;
}

.Home_main_lower_div {
  /* padding: 50px 50px; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.box-slider-card {
  display: flex;
  justify-content: center;
}

.Home_main_lower_box {
  border: 1px solid #f0f0f0;
  text-align: center;
  padding: 20px 15px;
  width: 400px !important;
  min-height: 100% !important;
  margin: 20px;
  /* Add some margin between boxes */
  /* box-sizing: border-box; Include padding in the width */
  transition: transform 0.3s ease;
  /* Add a smooth transition effect */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Home_main_lower_box:hover {
  transform: scale(1.05);
  /* Add a slight scale effect on hover */
}

.Home_main_lower_box_Link {
  color: #454545;
  font-size: 21px;
  font-weight: 600;
  text-decoration: none;
  margin-top: 20px;
}

.Home_main_lower_box_Link:hover {
  color: #258754;
}

.Home_main_lower_box_circle {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
}

.Home_main_lower_box_circle > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Home_main_lower_box > p {
  color: #757575;
  font-size: 14px;
  width: 100%;
  margin-top: 15px;
  /* Adjust spacing between heading and paragraph */
}
.slick-slide {
  width: 200px;
}

.teacher-data-main-page {
  max-width: 1174px;
  margin: 0 auto;
  width: 100%;
}

.booked-date-indicator {
  background-color: #4CAF50;
  color: white;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  margin: 0 auto;
}

.react-calendar__tile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 44px;
}

.react-calendar__tile > abbr {
  margin-bottom: 4px;
}
/* ------------------------------------------------------------------- */

/* 
.course-main-lower-div {
  padding: 50px;
}

.slider-div {
  width: 100%;
}

.home-main-lower-box {

  border: 1px solid #f0f0f0;
  text-align: center;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important  ;
  margin-left: 30px;
  padding: 20px;
}

.home-main-lower-box-circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;

}

.home-main-lower-box-circle img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.home-main-lower-box-link {
  text-decoration: none;
  color: black;
}

.text-success {
  color: green;
}
 */

/* ------------------------------------------------------------------  About style */

.About_main_div {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  perspective: 1000px;
}

.About_main_column_div {
  width: 100%;
  padding: 0rem 5rem;
  margin: 0px -15px 0px -15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.About_main_column_left_div {
  width: 100%;
  max-width: 585px;
  padding: 0px 15px 0px 15px;
  transform-style: preserve-3d;
  transition: transform 0.5s;
}

.About_main_column_left_div:hover {
  transform: translateZ(20px);
}

.About_main_column_left_div > h2 {
  font-size: 28px;
  margin: 20px 0 10px 0;
  padding: 0px 0px 10px 0px;
  display: block;
  color: #353535;
  font-weight: 700;
  font-family: Muli, sans-serif;
  transition: transform 0.3s;
}

.About_main_column_left_div > h2:hover {
  transform: translateZ(10px);
}

.About_main_column_left_div_col {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.About_main_column_left_div_col > span {
  margin-right: 20px;
}

.About_main_column_left_div_col > span > i {
  font-size: 25px;
  line-height: 50px;
  text-align: center;
  background-color: #29ca8e;
  height: 50px;
  width: 50px;
  display: inline-block;
  color: white;
  border-radius: 50%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s;
}

.About_main_column_left_div_col:hover > span > i {
  transform: scale(1.2) translateZ(20px);
}

.About_main_column_left_div_col_right > h3 {
  font-size: 21px;
  font-weight: 700;
  line-height: 30px;
  margin: 0;
  color: #454545;
  transition: transform 0.3s;
}

.About_main_column_left_div_col_right > h3:hover {
  transform: translateZ(10px);
}

.About_main_column_left_div_col_right > p {
  font-size: 14px;
  font-weight: 200;
  line-height: 24px;
  margin: 0;
  color: #757575;
  transition: transform 0.3s;
}

.About_main_column_left_div_col_right > p:hover {
  transform: translateZ(10px);
}

.About_main_column_right_div {
  min-height: inherit;
  padding: 0px 10px 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  perspective: 1000px;
}

.About_main_column_right_form_div {
  width: 450px;
  height: 450px;
  border-radius: 50%;
  padding: 34px 74px 34px 74px;
  background-color: #29ca8e;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
  transform-style: preserve-3d;
  transition: transform 0.5s;
}

.About_main_column_right_form_div:hover {
  transform: translateZ(50px);
}

.About_main_column_right_form_div > h2 {
  font-size: 18px;
  padding: 0;
  text-align: center;
  color: white;
}

.About_main_column_right_form_div > form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.input_style {
  height: 32px;
  margin: 0px 0px 6px 0px;
  padding: 6px 12px 6px 12px;
  border: none;
  outline: none;
  background-color: #29ca8e;
  border-bottom: 1px solid #f0f0f0;
  color: #f0f0f0;
}

.phonenumberinput {
  margin-left: 40px !important;
  width: 285px !important;
}

.teacherphonenumberinput {
  margin-left: 40px !important;
  width: 219px !important;
}

---------------------------------------------------------------  Our teacher style

.ourteacher_main_page_div {
  min-width: 100%;
  background-color: rgb(249, 249, 249);
}

.ourteacher_main_upper_div {
  padding: 18px;
  /* background-color: red; */
  text-align: center;
  line-height: 8px;
  margin-top: 20px;
}

.ourteacher_main_upper_div > h2 {
  font-size: 28px;
  color: #353535;
}

.ourteacher_main_upper_div > small {
  font-size: 18px;
  color: #777777;
}

.ourteacher_main_lower_div {
  /* height: 425px; */
  /* background-color: red; */

  display: flex;
}

.ourteacher_main_lower_box {
  border-radius: 10px;
}

.ourteacher_main_lower_div_img {
  width: 100%;
  height: 276px;
  object-fit: cover;
}

.ourteacher_main_lower_box {
}

.ourteacher_main_lower_box_div {
  width: 100%;

  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  line-height: 5px;
  padding: 20px;
  border-radius: 10px;
}

.ourteacher_main_lower_box_Link_h3 {
  font-size: 21px;
  font-weight: 700;
  color: #454545;
}

.ourteacher_main_lower_box_Link_h3:hover {
  color: #3bca8e;
}

.ourteacher_main_lower_box_div > h3 {
  font-size: 21px;
  font-weight: 700;
  color: #454545;
}

.ourteacher_main_lower_box_lower_div {
  height: 45px;
  width: 262px;
  border-top: 1px solid black;
  background-color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: start;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.hoverlink {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  font-size: 15px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #3bca8e;
  margin-left: 18px;
}

.hoverlink:hover {
  background-color: #3bca8e;
  color: white;
}

/* ----------------------------------------------------- Reviews style */

.reviews_main_page_div {
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #f9f9f9;
  gap: 20px;
}

.reviews_main_upper_div {
  padding: 18px;
  /* background-color: red; */
  text-align: center;
  line-height: 8px;
  margin-top: 20px;
}

.reviews_main_upper_div > h2 {
  font-size: 28px;
  color: #353535;
}

.reviews_main_upper_div > small {
  font-size: 18px;
  color: #777777;
}

.reviews_main_lower_div {
  /* ackground-color: red; */
  display: flex;
}

.reviews_main_lower_box {
  width: 350px;
  background-color: #ffffff;
  margin: auto;
  padding: 56px 42px 56px 42px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.reviews_main_lower_box_header {
  width: 100%;
  display: flex;
}

.reviews_main_lower_box_header_circle {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
}

.reviews_main_lower_box_header_circle > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.reviews_main_lower_box_header_heading {
  /* background-color: black; */
  padding: 7px 20px;
  line-height: 0px;
}

.reviews_main_lower_box_header_heading > h4 {
  font-size: 18px;
  color: #353535;
}

.reviews_main_lower_box_header_heading > span {
  font-size: 14px;
  color: #757575;
}

.reviews_main_lower_box > p {
  font-size: 16px;
  margin-top: 20px;
  text-align: start;
  color: #757575;
}

.reviews_main_lower_box_footer {
  display: flex;
  align-items: center;
}

.reviews_main_lower_box_footer > i {
  margin-right: 10px;
  color: #3f51b5;
  font-size: 14px;
}

/* --------------------------------------------------  contact us div */

.contact_main_page_div {
  /* height: 662px; */
  background-color: rgb(255, 255, 255);
  padding: 0px 0px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact_main_page_div > form {
  width: 50%;
  height: 100%;
  /* margin-top: 170px; */
}

.contact_main_page_left_div {
  width: 100%;
  height: initial;
  display: flex;
  flex-direction: column;
  /* background-color: red; */
  justify-content: space-between;
  padding: 50px 120px;
}

.contact_main_page_left_div_header {
  line-height: 10px;
}

.contact_main_page_left_div_header > h2 {
  font-size: 28px;
  color: #252020;
}

.contact_main_page_left_div_header > small {
  font-size: 14px;
  color: #252020;
}

.contact_main_page_left_div_input {
  width: 100%;
  padding: 12px;
  background-color: rgb(255, 255, 255);
  outline: none;
  border: none;
  border-radius: 6px;
  border-bottom: 1px solid #2daa2d;
  color: #757575;
  margin-top: 20px;
}

.Navlink.active {
  background-color: #3bca8e;
  color: white;
}

.contact_main_page_left_div_input-textaraa {
  width: 100%;
  padding: 12px;
  height: 130px;
  background-color: rgb(255, 255, 255) 0;
  outline: none;
  border: none;
  border-radius: 6px;
  color: #757575;
  text-align: start;
  border-bottom: 1px solid green;
}

.contact_main_page_left_div_submit_btn {
  padding: 10px;
  width: 30%;
  color: #757575;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  outline: none;
  border: none;
  margin-top: 20px;
}

.contact_main_page_right_div {
  width: 50%;
  height: initial;
  padding: 50px 120px;
}

.contact_main_page_right_img_div {
  /* background-color: red; */
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.contact_main_page_right_img_div > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* ---------------------------------------------------------------------------footer page style */

.footer_main_div {
  background-color: #353535;
  padding: 100px 50px 100px 50px;
}

.footer_main_box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer_main_box_col_1 {
  padding: 20px;
  /* background-color: red; */
}

.footer_main_box_col_1 > h2 {
  font-size: 20px;
  color: #ffffff;
}

.footer_main_box_col_1 > p {
  color: #909090;
  font-size: 12px;
  text-align: start;
  width: 70%;
  /* line-height: 7px; */
}

.footer_main_box_col_1_links_div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: start;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 50%;
}

.footer_main_box_col_1_link {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 10px;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin: auto;
}

.footer_main_box_col_1_lower_links_div {
  width: 80%;
  /* background-color: red; */
}

.footer_main_box_col_1_lower_links {
  text-decoration: none;
  font-size: 14px;
  color: #909090;
  margin-right: 15px;
}
.Packages_list_style {
  overflow: scroll;
}
.footer_main_box_col_2 {
  padding: 0px 66px;
  /* background-color: red; */
}

.footer_main_box_col_2 > h2 {
  font-size: 20px;
  color: #ffffff;
}

.footer_main_box_col_2 > p {
  color: #909090;
  font-size: 12px;
  text-align: start;
  width: 70%;
  /* line-height: 7px; */
}

.footer_main_box_col_2_links_div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: start;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 50%;
}

.footer_main_box_col_2_lower_links_div {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* background-color: red; */
}

.footer_main_box_col_2_lower_links {
  text-decoration: none;
  font-size: 14px;
  color: #909090;
  margin-right: 15px;
}

.footer_main_box_col_3 {
  padding: 40px 0px;
  /* background-color: red; */
}

.footer_main_box_col_3 > h2 {
  font-size: 20px;
  color: #ffffff;
}

.footer_main_box_col_3_input {
  width: 100%;
  background-color: #353535;
  border: none;
  outline: none;
  padding: 10px;
  border-bottom: 1px solid #909090;
  color: #909090;
}

/* -----------------------------------------------teachers */

.teachers_main_div {
  width: 100%;
  /* min-height: 300px; */
  /* height: 500px; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
}

.teachers_left_div {
  /* height: 100%; */
  /* width: 25%; */
  height: 100%;
}

.teachers_left_div > img {
  width: 250px;
  height: 300px;
}

.teachers_right_div {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 53px;
}

.teachers_right_div_link {
  text-decoration: none;
  color: #252020;
  margin-top: 20px;
}

.teachers_right_div_btn {
  width: 240px;
}

.teachers_right_div_link:hover {
  color: #258754;
}

/* ------------------------------------------------------------------------TeacherDetails */

.TeacherDetails_main_div {
  width: 100%;
  /* min-height: 80vh; */
  display: flex;
  justify-content: center;
  /* align-items: center; */
padding: 69px 5%;
}

.TeacherDetails_left_col {

  height: 80vh;
  /* background-color: black; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.TeacherDetails_left_img_col {
  width: 98%;
  height: 100%;
  /* background-color: red; */
}

.TeacherDetails_left_img_col > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.TeacherDetails_right_col {
  width: 65%;
  min-height: inherit;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.TeacherDetails_right_col > span {
  color: #757575;
}

.TeacherDetails_right_col > h2 {
  color: #252020;
}

.TeacherDetails_right_col_line {
  background-color: #252020;
  padding: 2px;
  margin-top: 20px;
  width: 50%;
}

.TeacherDetails_right_col > p {
  color: #757575;
  text-align: start;
  width: 75%;
  line-height: 30px;
  margin-top: 20px;
}

/* -----------------------------------------------------------------------scheduledpage_main_div */

.scheduledpage_calender {
  border-radius: 10px;
  border: 1px solid #258754;
}

.scheduledpage_calender_div {
  display: flex;
  flex-direction: column;
}

.scheduledpage_main_div {
  
  display: flex;
  justify-content: space-between;
  align-items: center;
 
}

.scheduledpage_time_div {
  width: 30%;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.scheduledpage_timeslot {
  width: 136px;
  background-color: white;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid #258754;
}
.scheduledpage_timeslot>p {
  margin: 0px;
}

.scheduledpage_timeslot > button {
  flex: 3;
  background-color: white;
  padding: 10px;
  border: 1px solid #28a745;
}

.scheduledpage_display_section {
  width: 30%;
  border-radius: 10px;
  border: 1px solid white;
  padding: 10px;
}

.scheduledpage_display_section > p:nth-child(3) {
  color: #258754;
}


/* ----------------------------------------------------------------------------------------------react-calender css */

.react-calendar {
  width: 400px;
  max-width: 100%;
  background-color: white;
  color: black;
  border-radius: 8px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}

.react-calendar__navigation button {
  color: black;
  min-width: 44px;
  background: none;
  font-size: 16px;
  margin-top: 8px;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}

.react-calendar__navigation button {
  min-width: 44px;
  /* background: none; */
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #258754;
}

.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}

.react-calendar__month-view__days__day--weekend {
  color: #258754;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #258754;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  background: none;
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background: #258754;
  color: white;
}

.react-calendar__tile--now {
  /* background: #ffff76; */
  background-color: white;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #258754;
  color: white;
}

.react-calendar__tile--hasActive {
  background: #258754;
  color: white;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #258754;
  color: white;
}

.react-calendar__tile--active {
  background: #258754;
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #258754;
  color: white;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background: #258754;
  color: white;
}

/*--------------------------------------------------------------------------Popup  */

.popup {
  width: 500px;
  height: 500px;
  position: absolute;
  top: 53%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
  background-color: white;
  border: 1px solid #258754;
  border-radius: 10px;
  padding: 50px 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.popup_header {
  width: 100%;
  padding: 2px;
  /* background-color: red; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup_header:nth-child(1) {
  color: #258754;
}

.popup_form_div {
  width: 100%;
  height: 90%;
  padding: 10px;
  /* background-color: white;   */
}

.popup_form_div > form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* background-color: red; */
  padding: 10px;
}

.popup_form_div > form > label {
  color: #252020;
}

.popup_form_div > form > input {
  width: 100%;
  border-radius: 5px;
  padding: 4px;
  border: none;
  outline: none;
  color: #258754;
  border-bottom: 1px solid #258754;
}

.no-spinners::-webkit-outer-spin-button,
.no-spinners::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* -----------------------------------------------------------------------------------------Dashboard */

.dashboard_main_div {
  min-height: 96.5vh;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboard_main_left_div {
  min-height: inherit;
  display: flex;
  flex-direction: column;
  /* background-color: red; */
  width: 18%;
  border-radius: 10px;
  align-items: center;
  justify-content: flex-start;
  padding: 5px;
}

.dashboard_main_right_div {
  min-height: inherit;
  width: 80%;
  padding: 10px;
  display: flex;
  border-radius: 10px;
  background-color: white;
}

.Dashboard_left_head_column {
  width: 100%;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-bottom: 2px solid rgba(229, 225, 225, 0.681);
  text-decoration: none;
  margin-bottom: 2px;
}

.Dashboard_left_head_column > h5 {
  font-weight: 700;
  font-size: 16px;
}

.Dashboard_left_column {
  width: 100%;
  border-radius: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 2px;
  text-decoration: none;
  color: white;
  background-color: #33906c;
}

.Dashboard_left_column_left_section {
  width: 20%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.Dashboard_left_column_right_section {
  padding: 2px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.Dashboard_left_column_right_section > h6 {
  margin-top: 8px;
  font-weight: 600;
}

.Dashboard_left_column.active {
  color: black;
  background-color: white;
}

.Dashboard_left_column.active > .Dashboard_left_column_left_section {
  color: black;
}

.Dashboard_left_column.active > .Dashboard_left_column_right_section {
  color: black;
}

.Dashboard_left_column.active > .Dashboard_left_column_right_section > h6 {
  color: black;
  font-weight: 600;
}

.Dashboard_left_column.inactive {
  color: white;
  background-color: #33906c;
}

/* ------------------------------------------------------------------------------------------CourseDetails */

.CourseDetails_main_div {
  width: 100%;
  /* background-color: #258754; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.CourseDetails_left_col {
  width: 35%;
  height: 80vh;
  /* background-color: black; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.CourseDetails_left_img_col > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.CourseDetails_right_col {
  width: 65%;
  min-height: inherit;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* justify-content: space-around; */
}

.CourseDetails_right_col > span {
  color: #757575;
}

.CourseDetails_right_col > h2 {
  color: #252020;
}

.CourseDetails_right_col_line {
  background-color: #252020;
  padding: 2px;
  margin-top: 20px;
  width: 40%;
}

.CourseDetails_right_col > p {
  color: #757575;
  text-align: start;
  margin-top: 20px;
}

.Courserelated_main_div {
  width: 100%;
  /* min-height: 80vh; */
  /* background-color: red; */
  padding: 20px 60px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
}

.Courserelated_main_div > span {
  font-size: 30px;
  font-weight: 599;
  color: #252020;
  margin-left: 75px;
}

.Courserelated_box_div {
  width: 100%;
  min-height: 60vh;
  /* background-color: red; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.Courserelated_card_div {
  width: 274px;
  height: 100%;
  /* background-color: blue; */
  border: 1px solid #757575;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  margin: auto;
}

.Courserelated_card_img_div {
  width: 100%;
  height: 200px;
  border-radius: 10px;
  overflow: hidden;
}

.Courserelated_card_img_div > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Courserelated_card_div_Link {
  font-size: 25px;
  color: #252020;
  margin-top: 10px;
  text-decoration: none;
  font-weight: 600;
}

.Courserelated_card_div_Link:hover {
  color: #258754;
}

.Courserelated_card_div > p {
  font-size: 15px;
  color: #252020;
  text-align: center;
}

/* -----------------------------------------------------------------------------------------------------------------------SigninFormPopup -------------------- */

@keyframes heartbeat {
  0% { transform: scale(1); }
  14% { transform: scale(1.02); }
  28% { transform: scale(1); }
  42% { transform: scale(1.02); }
  70% { transform: scale(1); }
}

.SignupFormPopup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.form-popup {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.form-popup-content {
  background-color: #fff;
  min-height: 45%;
  width: 500px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  animation: heartbeat 1.5s infinite;
}

.form-popup-content.paused {
  animation-play-state: paused;
}

.form-popup-content > form {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.form-group-sign {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90%;
}

.form-popup-content > form > label {
  font-size: 14px;
  color: #333 !important;
  margin-bottom: 5px;
  font-weight: 600;
}

.form-group-sign > input {
  border: none;
  outline: none;
  width: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 12px 15px;
  margin-bottom: 15px;
  font-size: 14px;
  transition: all 0.3s ease;
}

.form-group-sign > input:focus {
  border-color: #107314;
  box-shadow: 0 0 0 2px rgba(16, 115, 20, 0.1);
}

.form-group-sign > input::placeholder {
  color: #999 !important;
  font-size: 14px;
  opacity: 1;
}

.form-popup-content h5 {
  font-size: 24px;
  color: #107314;
  margin-bottom: 20px;
  font-weight: 600;
}

.form-popup-content button {
  background-color: #107314;
  color: white;
  border: none;
  padding: 12px 20px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 45%;
  margin-top: 10px;
}

.form-popup-content button:hover {
  background-color: #0a5a0e;
}

.form-popup-content .button-group {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.form-popup-content a {
  color: #107314;
  text-decoration: none;
  font-size: 14px;
  margin-top: 15px;
  transition: all 0.3s ease;
}

.form-popup-content a:hover {
  text-decoration: underline;
}

.error {
  color: red;
  font-size: 12px;
  margin-top: -10px;
  margin-bottom: 10px;
}

/* Switch box styles */
.register-switch-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin: 0 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: #fff;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #107314;
}

input:focus + .slider {
  box-shadow: 0 0 1px #107314;
}

input:checked + .slider:before {
  transform: translateX(26px);
}
/* -----------------------------------------------------------------------------------------------------------------------SignupFormPopup -------------------- */

@keyframes heartbeat {
  0% { transform: scale(1); }
  14% { transform: scale(1.02); }
  28% { transform: scale(1); }
  42% { transform: scale(1.02); }
  70% { transform: scale(1); }
}

.register-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.register-content {
  background-color: #fff;
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  width: 600px;
  max-height: 90vh;
  overflow-y: auto;
  animation: heartbeat 1.5s infinite;
}

.register-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.register-title {
  font-size: 24px;
  color: #107314;
  text-align: center;
  margin-bottom: 20px;
  font-weight: 600;
}

.register-popup.paused .register-content {
  animation-play-state: paused;
}

.register-input-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.register-input {
  padding: 12px 15px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 14px;
  width: 100%;
  transition: all 0.3s ease;
}

.register-input:focus {
  border-color: #107314;
  outline: none;
  box-shadow: 0 0 0 2px rgba(16, 115, 20, 0.1);
}

.register-otp-container {
  display: flex;
  gap: 10px;
  align-items: center;
}

.register-otp-input {
  flex-grow: 1;
}

.register-otp-button {
  padding: 12px 15px;
  background-color: #107314;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
  white-space: nowrap;
  min-width: 100px;
  font-size: 14px;
  font-weight: 600;
}

.register-otp-button:hover {
  background-color: #0a5a0e;
}

.register-otp-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.register-password-container {
  position: relative;
}

.register-password-toggle {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  color: #666;
}

.register-checkbox-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.register-checkbox-label {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  cursor: pointer;
  color: #333;
}

.register-checkbox {
  width: 18px;
  height: 18px;
  border: 2px solid #107314;
  border-radius: 3px;
  appearance: none;
  cursor: pointer;
}

.register-checkbox:checked {
  background-color: #107314;
  position: relative;
}

.register-checkbox:checked::after {
  content: '✓';
  position: absolute;
  color: white;
  font-size: 14px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.register-button-container {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  margin-top: 20px;
}

.register-submit-button.inactive {
  opacity: 0.5;
  cursor: not-allowed;
  background-color: #ccc; 
}

.register-submit-button.active {
  opacity: 1;
  cursor: pointer;
  background-color: #107314; 
}

.register-submit-button,
.register-close-button {
  flex: 1;
  padding: 12px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s;
  transition: all 0.3s ease;
}

.register-submit-button {
  background-color: #107314;
  color: white;
}

.register-submit-button:hover {
  background-color: #0a5a0e;
}

.register-close-button {
  background-color: #f0f0f0;
  color: #333;
}

.register-close-button:hover {
  background-color: #e0e0e0;
}

.register-error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

/* Styles for react-phone-input-2 */
.register-phone-input {
  width: 100% !important;
}

.register-phone-input .form-control {
  width: 100% !important;
  padding: 12px 12px 12px 50px !important;
  border: 1px solid #e0e0e0 !important;
  border-radius: 8px !important;
  font-size: 14px !important;
  height: auto !important;
  transition: all 0.3s ease;
}

.register-phone-input .form-control:focus {
  border-color: #107314 !important;
  box-shadow: 0 0 0 2px rgba(16, 115, 20, 0.1) !important;
}

.register-phone-input .flag-dropdown {
  border: none !important;
  background-color: transparent !important;
}

.register-phone-input .selected-flag {
  background-color: transparent !important;
  border-radius: 8px 0 0 8px !important;
}

.register-phone-input .country-list {
  max-height: 150px !important;
}

.register-otp-container .react-tel-input {
  flex-grow: 1;
}

.register-checkbox-label a {
  color: #0000EE;
  text-decoration: underline;
  cursor: pointer;
}

/* --------------------------------------------------------------------------------chatbotbtn */

.chatbotbtn {
  position: fixed;
  bottom: 20px;
  right: 1%;
  padding: 0.5rem 1rem;
  font-family: Nunito;
  font-size: 1.5rem;
  background-color: rgb(51, 144, 108);
  color: #fff;
  border: none;
  border-radius: 10%;
  cursor: pointer;
}

/* ---------------------------------------------------------------------------------------------------------------NotificationTab */

.Notification_main_div {
  width: 100%;
  height: 89%;
  padding: 20px 30px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2), 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  overflow-x: hidden;
  overflow-y: auto;
}

.notification_div {
  width: 100%;
  border-radius: 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  font-size: 12px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2), 0 2px 5px 0 rgba(0, 0, 0, 0.1);
}

.notification_div > button {
  font-size: 12px;
  padding: 5px 10px;
}

.responsive-menu {
  position: fixed;
  /* width: 100%; */
  padding: 20px;
      border-bottom: 1px solid green;
    border-left: 1px solid green;
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 63px;
  right: 0;
  z-index: 9999;
  background: #fff;
}

.top-sliderMain {
  /* max-width: 1250px; */
  height: 800px;
  width: 100%;
  margin: 0 auto;
}

.react-tel-input .form-control {
  position: relative;
  font-size: 14px;
  letter-spacing: 0.01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;
  background: #ffffff;
  border: 1px solid #168d09;
  border-radius: 5px;
  line-height: 25px;
  /* height: 35px; */
  width: 100%;
  outline: none;
  max-width: 83%;
}
.Home_main_upper_div_col3.slick-next {
  display: none !important;
}
.Home_main_upper_div_col3.slick-next ::before {
  display: none !important;
}
/* --------------------------------------------------------------------------------------------------------------------------------------------------------responsive */
.form-popup-content {
  width: 35%;
}

.custom-modal {
  width: 60vw !important;
  max-width: 80vw !important;
}

.calendar-slots-container {
  gap: 200px; /* Adjust the value as needed */
}


.calendar-slots-container {
  gap: 20px;
}

.calendar-container,
.selected-slots-container {
  flex: 1;
}

.available-slots-container {
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.date-slots {
  margin-bottom: 20px;
}

.slot-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.slot-item input {
  margin-right: 10px;
}

.slot-item label {
  margin: 0;
}

.selected-slots-container {
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.teacher-slots {
  margin-bottom: 20px;
}

.date-slots h5 {
  margin-bottom: 10px;
}

.slot-list {
  padding-left: 20px;
}

.gapOfDeletBT {
  justify-content: space-between;
}

.delete-slot-btn {
  display: flex;
  align-items: center;
}


.phone_style {
  height: inherit;
  padding: 0px;
  /* margin: 0px 0px 6px 0px ; */
}

.custom-phone-input input {
  padding-left: 50px !important;
}

/* --------------------------------------------------------------------------------------------------------------------------------------------------------Selected slots code */
.selected-slots-container {
  margin-top: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 15px;
  background-color: #f9f9f9;
}

.slot-date {
  font-weight: 600;
  color: #333;
  margin-bottom: 10px;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 5px;
}

.slot-list {
  list-style-type: none;
  padding: 0;
}

.slot-item {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 8px 12px;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.slot-time {
  font-weight: 500;
}

.teacher-name {
  color: #666;
  font-size: 0.9em;
}

/* -----------------------------------------------------recaptcha */

.rc-anchor-alert {
  display: none !important;
}

.rc-anchor-normal-footer {
  display: none !important;
}

#rc-anchor-container {
  height: 60px !important;
  width: 160px !important;
}

/* --------------------------------------------------our teacher */

.ourteacher_main_lower_box {
  margin-top: 20px;
  width: 86%;
}

.ourteacher_main_page_div {
  justify-content: start;
  padding: 0px;
  min-height: initial;
}

.ourteacher_main_lower_div {
  flex-direction: column;
  height: initial;
}

/* ---------------------------------------------------------------------review */

.reviews_main_page_div {
  min-width: 100% !important;
}

.reviews_main_lower_box {
  margin-top: 20px;
}

/* ---------------------------------------------------------------------contact */

.contact_main_page_div {
  min-height: initial;
  padding: 50px 0px 50px 0px;
}

.contact_main_page_left_div {
  width: 100%;
  padding: 0px 40px;
}

.contact_main_page_left_div_input {
  /* margin-top: 20px; */
  padding: 5px;
}

.contact_main_page_left_div_submit_btn {
  padding: 8px;
  width: 55%;
  margin-top: 20px;
}

/* --------------------------------------------------------footer */

.footer_main_div {
  padding: 50px;
  height: initial;
}

.footer_main_box_col_2 {
  padding: 20px;
}

/* -------------------------------------------------------------scheduled responsive */

.scheduledpage_main_div {
  flex-direction: column;
  padding-top: 50px;

}

.scheduledpage_time_div {
  margin-top: 60px;
  width: 80%;
}

.scheduledpage_display_section {
  width: 80%;
  margin-top: 30px;
}

/* ----------------------------------------------------------------------------.CourseDetails_main_div */



.CourseDetails_right_col {
  width: 80%;
}

.CourseDetails_right_col > button {
  width: 50% !important;
}

.Courserelated_main_div {
  padding: 0px;
}

/* .Courserelated_card_div {
  margin-top: 20px;
  width: 55%;
} */

.TeacherDetails_left_col {
  width: 40%;
}

.TeacherDetails_right_col {
  width: 60%;
}

.Courserelated_main_div > span {
  margin-top: 20px;
}

/* ------------------------------------------------------------------------.form-popup { */

/* .form-popup {
  width: 100%;
} */

.phonenumberinput {
  margin-left: 40px !important;
  width: 145px !important;
}

.react-calendar {
  width: auto;
}

/* ----------------------------------------------------------------------------------Teacher Details */

.TeacherDetails_right_col_line {
  width: 250%;
}

.TeacherDetails_right_col > p {
  width: 100%;
}

.TeacherDetails_right_col > button {
  width: 50% !important;
}

.reviews_main_lower_box {
  width: 80%;
  margin: 0;
  padding: 10px;
}
.reviews_main_lower_div {
  align-items: center;
  gap: 9%;
  padding: 0 6rem;
}
.teacherBoxMain {
  background-color: #33906c;
  border-radius: 5px;
  /* margin-top: 10px; */
  padding: 5px;
  font-size: 12px;
  color: white;
  width: 100%;
}
.confirm-Box {
  gap: 20px;
}
.PersonalInfo {
  width: 45%;
}

.OrderInfo {
  width: 45%;
}

.css-13cymwt-control {
  border-color: #58cd58 !important;
}

input.form-control {
  font-size: 15px !important;
}
p.Paymessage {
  font-size: 21px;
  font-weight: 500;
}
.successDiv {
  border: 4px solid;
  margin: 0;
  padding: 1rem;
  border-radius: 14px;
  margin: 9px 0px;
}




@media only screen and (max-width: 1093px) {
  .top-sliderMain {
    height: 673px;
    width: 100%;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 1024px) {
   .bannereDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80%;
     padding: 20% 1rem 1rem 8%;
    color: white;
}
  .footer_main_box_col_3 {
    width: 100%;
    padding: 20px;
  }
  .contact_main_page_div > form {
    width: 95%;
  }
  .contact_main_page_div {
    min-height: initial;
    padding: 20px 0px 20px 0px;
  }
  .footer_main_box_col_1 {
    padding: 20px;
    width: 100%;
  }
  .form-popup-content {
    width: 80%;
  }
  .teacher-data-main-page {
    margin: 0 auto;
  }
  .contact_main_page_right_div {
    display: none;
  }
  /* show element on medium-sized screens */
  .chatbotbtn {
    right: 9%;
  }
  .reviews_main_lower_div {
    flex-direction: column;
    padding: 0 1rem;
    gap: 10px;
  }
  .Home_text_div_col2 > h3 {
    width: 115%;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 8px;
  }
  .Home_text_div_col2 > h1 {
    font-size: 35px;
  }
  .Home_text_div_col2 > button {
    width: 34%;
    font-size: 12px;
    padding: 18px;
    margin-top: 20px;
  }
  .Home_text_div_col2 {
    left: 55%;
    width: 100%;
  }

  .Home_text_div_col3 > h1 {
    font-size: 27px;
  }
  .reviews_main_page_div {
    padding: 1rem;
  }
  .Home_text_div_col3 > button {
    width: 34%;
    font-size: 12px;
    padding: 18px;
    margin-top: 20px;
  }

  .Home_text_div_col3 > h3 {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 9px;
    line-height: 11px;
  }

  .Home_text_div_col3 {
    width: 80%;
  }

  .Home_text_div_col1 > h3 {
    font-size: 9px;
    line-height: 11px;
  }

  .Home_text_div_col1 > button {
    width: 34%;
    font-size: 12px;
    padding: 18px;
    margin-top: 20px;
  }

  .Home_text_div_col1 > h1 {
    font-size: 28px;
  }
  .Home_text_div_col1 {
    left: 55%;
    width: 100%;
  }

  .Home_main_upper_div {
    /* background-color: red; */
    /* padding: 20px 30px; */
  }

  /* --------------------------------------------nav*/

      .Navbar_main {
        position: fixed;
        justify-content: space-between;
        padding: 0rem 1rem;
    }

  .Navbar_nav_div {
    display: none;
  }

  .nav-btn-sign {
    font-size: 8px !important;
  }

  .menu-button {
    display: initial !important;
  }

  /* 
      #Courses {
        display: none;
      } */

  #Courses_2 {
    display: flex;
  }

  .course_main-Box {
    max-width: 150px;
  }

  /* -----------------------------------------------------------teacher */

  .teachers_main_div {
    padding: 0px;
    display: flex;
    flex-direction: column;
  }

  .teachers_right_div {
    width: 80%;
  }

  .teachers_right_div_btn {
    width: 200px;
  }

  /*---------------------------------------------------------------courses */
  .top-sliderMain {
    height: 457px;
  }
  .Home_main_lower_div {
    padding: 50px 50px;
    display: flex;
    flex-direction: column;
    /* background-color: red; */
  }

  .Home_main_lower_box {
    border: none;
    padding: 0;
    width: 260px !important;
  }

  .Home_main_lower_box > p {
    color: #757575;
    font-size: 10px;
  }

  .course_main-Box {
    max-width: 252px !important;
  }

  /* -------------------------------------------------------About */

  .About_main_column_div {
    flex-direction: column;
    margin-top: 40px;
    gap: 15px;
    padding: 0rem 1rem;
  }

  .About_main_column_left_div > h2 {
    font-size: 16px;
  }

  .About_main_column_left_div_col {
    height: initial;
  }

  .About_main_column_left_div_col_right > h3 {
    font-size: 14px;
  }

  .About_main_column_left_div_col_right > p {
    font-size: 10px;
  }

  .About_main_div {
    /* padding: 0px 50px 0px 50px; */
    align-items: flex-start;
  }

  .About_main_column_left_div {
    padding: 1rem 2rem;
  }

  .About_main_column_right_div {
    padding: 0;
  }

  .About_main_column_right_form_div {
    border-radius: 0;
    border-radius: 0;
    padding: 3rem 1rem;
    width: 100%;
  }
  .Courserelated_box_div {
    min-height: auto;
  
  }
 
}
@media only screen and (max-width: 840px){

  .footer_main_box {
    flex-direction: column;
    align-items: flex-start;
  }

}

@media only screen and (max-width: 773px){

}
@media only screen and (max-width: 538px) {
  .top-sliderMain {
    height: 333px;
  }
  .Navbar_nav_firstdiv {
    width: 36px;
    height: 36px;
    border-radius: 50%;
}
  .bannereDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
        gap: 6px;
    color: white;
}
.footer_main_div {
  padding: 21px;
  height: initial;
}
.TeacherDetails_main_div {
  flex-direction: column;
}
.TeacherDetails_left_col {
  width: 63%;
}

.TeacherDetails_right_col {
  width: 100%;
  padding: 10px;
}
button.btn.btn-outline-light.rounded-5.banner-Btn{
    padding: 0.3rem 1rem;
    font-size: 12px;
}
.bannereDiv >h2 {
  font-size: 20px;
}
.bannereDiv >h6 {
  font-size: 10px;
}
  .footer_main_box {
    flex-direction: column;
  }
}
@media only screen and (max-width: 480px) {
  .teacher-data {
    flex-direction: column;
  }
  .teachers_right_div {
    margin: 0;
    align-items: center;
    gap: 0;
  }
  .reviews_main_lower_box {
    width: 80%;
  }
  .confirm-Box {
    gap: 20px;
    flex-direction: column;
  }
  .PersonalInfo {
    width: 90%;
  }
  .top-sliderMain {
    /* max-width: 1250px; */
    height: 293px;
    width: 100%;
    margin: 0 auto;
  }
  .OrderInfo {
    width: 90%;
  }
  .footer_main_box {
    flex-direction: column;
  }
  .TeacherDetails_right_col > p {
    line-height: 24px;
  }
}
.error{
  color: red;
}

  /* -------------------------------------------------------STUDENT PACKAGE BUY */



  .package-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  }
  
  .page-title {
    text-align: center;
    margin-bottom: 20px;
    color: #000000;
    font-size: 24px;
    font-weight: 600;
  }
  
  .teacher-select {
    margin-bottom: 20px;
  }
  
  .basic-single {
    font-size: 16px;
  }
  
  .select__control {
    border-color: #d9d9d9;
    box-shadow: none;
  }
  
  .select__control:hover {
    border-color: #40a9ff;
  }
  
  .select__control--is-focused {
    border-color: #40a9ff;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
  
  .select__option--is-focused {
    background-color: #e6f7ff;
  }
  
  .select__option--is-selected {
    background-color: #1890ff;
  }
  
  .calendar-container {
    margin-bottom: 20px;
  }
  
  .selected-slots {
    background-color: #f0f2f5;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
    border: 1px solid #e0e0e0;
  }
  
  .slot {
    background-color: #e6f7ff;
    padding: 10px;
    margin: 5px 0;
    border-radius: 8px;
    border: 1px solid #91d5ff;
    color: #107314;
  }
  
  .submit-button {
    display: block;
    margin: 0 auto;
    background-color: #107314;
    color: white;
    border: none;
    padding: 0px 20px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #0a5a0e;
  }
  
  /* FullCalendar styles */
  .fc .fc-toolbar-title {
    color: #107314;
  }
  
  .fc .fc-button-primary {
    background-color: #107314;
    border-color: #107314;
  }
  
  .fc .fc-button-primary:not(:disabled).fc-button-active,
  .fc .fc-button-primary:not(:disabled):active,
  .fc .fc-button-primary:hover {
    background-color: #0a5a0e;
    border-color: #0a5a0e;
  }
  
  /* Base styles for all events */
.fc-event {
  cursor: pointer;
  position: relative;
  overflow: visible; /* Allow pseudo-element to overflow */
}

/* Status-specific styles */
.fc-event.scheduled-slot {
  background-color: #4CAF50 !important;
  border-color: #4CAF50 !important;
}

.fc-event.rescheduled-slot {
  background-color: #FFC107 !important;
  border-color: #FFC107 !important;
}

.fc-event.cancelled-slot {
  background-color: #F44336 !important;
  border-color: #F44336 !important;
}

.fc-event.completed-slot {
  background-color: #2196F3 !important;
  border-color: #2196F3 !important;
}

.fc-event.available-slot {
  background-color: #5C7153 !important;
  border-color: #5C7153 !important;
}

/* Ensure text is visible on all background colors */
.fc-event-title,
.fc-event-time {
  color: #ffffff !important;
  text-shadow: 0 0 2px rgba(0,0,0,0.7);
}

/* Styles for booked slots */
.fc-event.booked-slot {
  background-color: #FF0000 !important;
  border-color: #FF0000 !important;
  cursor: not-allowed;
}



/* Pseudo-element for the wider background effect */
.fc-event::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -5px;
  right: -5px;
  bottom: -2px;
  background-color: inherit;
  z-index: -1;
  border-radius: 4px;
}

/* Ensure the event content is on top of the background */
.fc-event-main {
  position: relative;
  z-index: 1;
}
  
  .fc-timegrid-slot {
    height: 40px !important;
  }
  
  .fc-timegrid-slot-label {
    font-size: 0.8em;
  }
  
  .fc-col-header-cell {
    background-color: #e6f7ff;
  }
  
  .fc-col-header-cell-cushion {
    color: #107314;
  }
  
  .fc-day-today {
    background-color: #e6f7ff !important;
  }
  
  .fc-daygrid-day-number,
  .fc-daygrid-day-top {
    color: #107314;
  }
  
  .fc-daygrid-day-events {
    min-height: 2em;
  }
  
  .fc-event-title,
  .fc-event-time {
    color: white;
  }
  
  .fc-list-day-cushion {
    background-color: #e6f7ff !important;
  }
  
  .fc-list-event:hover td {
    background-color: #f0f2f5;
  }
  
  .fc-event::before {
    content: '';
    position: absolute;
    top: -2px;
    left: -5px;
    right: -5px;
    bottom: -2px;
    background-color: inherit;
    z-index: -1;
    border-radius: 4px;
  }
  
  .fc-content {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
    padding: 2px 5px;
  }
  
  .fc-content input[type="checkbox"] {
    margin-right: 5px;
  }
  
  .fc-content span {
    color: white;
    font-weight: bold;
    text-shadow: 0 0 2px rgba(0,0,0,0.5);
  }
  
  /* Custom checkbox styles */
  .fc-content input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    width: 18px;
    height: 18px;
    border: 2px solid white;
    border-radius: 3px;
    outline: none;
    cursor: pointer;
    margin-right: 5px;
  }
  
  .fc-content input[type="checkbox"]:checked {
    background-color: white;
    position: relative;
  }
  
  .fc-content input[type="checkbox"]:checked::before {
    content: '\2714';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #107314;
    font-size: 12px;
  }

  .week-checkbox-container {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
  }
  
  .week-checkbox {
    margin-right: 5px;
  }

  .calendar-layout {
    display: flex;
    align-items: flex-start;
  }
  
  .week-selector {
    width: 150px;
    margin-right: 20px;
  }
  
  .week-checkbox-container {
    margin-bottom: 10px;
  }
  
  .fc {
    flex-grow: 1;
  }

  /* Status-specific styles */
.fc-event.scheduled-slot::before { background-color: #4CAF50; }
.fc-event.rescheduled-slot::before { background-color: #FFC107; }
.fc-event.cancelled-slot::before { background-color: #F44336; }
.fc-event.completed-slot::before { background-color: #2196F3; }
.fc-event.available-slot::before { background-color: #5C7153; }

/* Ensure the events don't become too wide on smaller screens */
@media (max-width: 768px) {
  .fc-event::before {
    left: -3px;
    right: -3px;
  }
}

    /* -------------------------------------------------------Admin Dashboard */

    .Admin-Dash_main_div {
      padding: 20px;
      background-color: #ffffff;

    }
    
    .Admin-Dash_header_style {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
    }
    
    .Admin-Dash_content_box {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
    
    .admin-card {
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2), 0 2px 5px 0 rgba(0, 0, 0, 0.1);
      border-radius: 10px;
    }
    
    .stat-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

        /* -------------------------------------------------------Wallet */

        .wallet-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.balance-section {
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.balance {
  font-size: 24px;
  font-weight: bold;
}

.add-funds-section form {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.add-funds-section input {
  flex-grow: 1;
  padding: 10px;
}

.add-funds-section button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.transaction-history ul {
  list-style-type: none;
  padding: 0;
}

.transaction-history li {
  border-bottom: 1px solid #eee;
  padding: 10px 0;
}

.total-balance {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.total-balance span {
  font-weight: bold;
  color: #007bff;
}

.package-balances {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.package-balance {
  background-color: #f8f9fa;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.package-name {
  font-weight: bold;
  margin-right: 0.5rem;
}

.package-hours {
  color: #28a745;
}

.hours-available {
  display: flex;
  align-items: baseline;
}

.hours-available strong {
  font-size: 1.2rem;
  color: #007bff;
}

.hours-label {
  font-size: 0.9rem;
  color: #6c757d;
  margin-left: 0.25rem;
}

.packages-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.package-card {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.package-name {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.package-details {
  margin-bottom: 15px;
}

.stat-box {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.stat-box .icon {
  margin-right: 10px;
  color: #007bff;
}

.package-price {
  margin-bottom: 15px;
}

.original-price {
  text-decoration: line-through;
  color: #888;
  margin-right: 10px;
}

.discounted-price {
  font-weight: bold;
  color: #28a745;
}

.discount-percentage {
  background-color: #28a745;
  color: white;
  padding: 2px 5px;
  border-radius: 3px;
  margin-left: 10px;
  font-size: 0.8rem;
}

.book-now-button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

.book-now-button:hover {
  background-color: #0056b3;
}

.packages-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  /* padding: 20px; */
}

.package-card {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.package-name {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 15px;
  color: #333;
}

.package-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.purchase-hours-button,
.book-now-button {
  flex: 1;
  padding: 0.5rem;
  margin: 0 0.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.purchase-hours-button {
  background-color: #4CAF50;
  color: white;
}

.book-now-button {
  background-color: #2196F3;
  color: white;
}

.purchase-hours-button:hover,
.book-now-button:hover {
  opacity: 0.9;
}

.calendar-communication {
  margin-top: 20px;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 5px;
  text-align: center;
}


/* -------------------------------------------------------Purchase Popup*/

/* Styles for react-select */
.react-select-container .react-select__control {
  border-color: #e0e0e0;
  border-radius: 8px;
}

.react-select-container .react-select__control:hover {
  border-color: #107314;
}

.react-select-container .react-select__control--is-focused {
  border-color: #107314;
  box-shadow: 0 0 0 2px rgba(16, 115, 20, 0.1);
}

.react-select-container .react-select__menu {
  border-radius: 8px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}

.react-select-container .react-select__option--is-focused {
  background-color: #e0f2fe;
}

.react-select-container .react-select__option--is-selected {
  background-color: #107314;
}

/* Styles for PurchasePopup */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background-color: #fff;
  padding: 30px;
  border-radius: 20px;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.popup-title {
  font-size: 24px;
  font-weight: 600;
  color: #107314;
}

.popup-close-button {
  background: none;
  border: none;
  color: #333;
  cursor: pointer;
  font-size: 24px;
}

.popup-info-box {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 12px 15px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.popup-info-label {
  display: flex;
  align-items: center;
  font-weight: 500;
  color: #333;
}

.popup-info-value {
  font-weight: 600;
  color: #107314;
}

.popup-select-label {
  display: block;
  font-size: 14px;
  font-weight: 600;
  color: #333;
  margin-bottom: 5px;
}

.popup-selected-payment {
  background-color: #e0f2fe;
  border: 1px solid #107314;
  border-radius: 8px;
  padding: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #107314;
  font-weight: 500;
}

.popup-purchase-button {
  width: 100%;
  background-color: #107314;
  color: white;
  padding: 12px 20px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  cursor: pointer;
}

.popup-purchase-button:hover {
  background-color: #0a5a0e;
}

.popup-no-hours {
  color: red;
  font-weight: 500;
  text-align: center;
}

/* Additional utility classes */
.mr-2 {
  margin-right: 8px;
}

.font-bold {
  font-weight: 700;
}

.ml-1 {
  margin-left: 4px;
}


/* -------------------------------------------------------Wallet Popup*/

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.popup-content button {
  margin: 10px;
  padding: 5px 10px;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.popup-content button {
  margin: 5px;
  padding: 5px 10px;
}

.popup-content button.selected {
  background-color: #4CAF50;
  color: white;
}

    /* -------------------------------------------------------Package booking Page */

    :root {
      --dark-green: #107314;
      --medium-green: #0a5a0e;
      --light-green: #ffffff;  /* Change this to white */
      --text-dark: #333;
      --text-light: #fff;
      --border-color: #e0e0e0;
      --error-color: #ff0000;
      --shadow-color: rgba(0, 0, 0, 0.2);
    }
    
    .Admin-Dash_main_div {
      position: relative;
      min-height: 100vh;
      background-color: var(--light-green);
    }
    
    .Admin-Dash_content_box {
      padding: 30px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
    }
    
    .Admin-Dash_header_style {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 30px;
      width: 100%;
    }
    
    .packages-title {
      font-size: 32px;
      color: var(--dark-green);
      font-weight: 600;
      margin-bottom: 20px;
      text-align: center;
    }
    
    .search-container {
      position: relative;
      width: 100%;
      max-width: 300px;
      margin-bottom: 30px;
    }
    
    .search-icon {
      position: absolute;
      left: 15px;
      top: 50%;
      transform: translateY(-50%);
      color: var(--dark-green);
    }
    
    .packages-search {
      width: 100%;
      padding: 12px 15px 12px 40px;
      border: 1px solid var(--border-color);
      border-radius: 8px;
      font-size: 14px;
      transition: all 0.3s ease;
    }
    
    .packages-search:focus {
      border-color: var(--dark-green);
      box-shadow: 0 0 0 2px rgba(16, 115, 20, 0.1);
    }
    
    .packages-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      gap: 30px;
      width: 100%;
    }
    
    .package-card.admin-card {
      background-color: var(--text-light);
      border-radius: 20px;
      box-shadow: 0 10px 30px var(--shadow-color);
      padding: 30px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      transition: all 0.3s ease;
      height: 100%;
    }
    
    .package-card.admin-card:hover {
      transform: translateY(-5px);
    }
    
    .package-card.admin-card > *:not(:last-child) {
      margin-bottom: 15px;
    }
    
    .package-name {
      font-size: 18px;
      color: var(--dark-green);
      font-weight: 600;
    }
    
    .package-details {
      display: flex;
      justify-content: space-between;
    }
    
    .stat-box {
      display: flex;
      align-items: center;
      color: var(--text-dark);
      font-size: 14px;
    }
    
    .stat-box .icon {
      margin-right: 5px;
      color: var(--dark-green);
    }
    
    .package-price {
      display: flex;
      align-items: center;
    }
    
    .original-price {
      text-decoration: line-through;
      color: var(--text-dark);
      margin-right: 10px;
      font-size: 14px;
    }
    
    .discounted-price {
      font-size: 18px;
      font-weight: 600;
      color: var(--dark-green);
    }
    
    .discount-badge {
      background-color: var(--dark-green);
      color: var(--text-light);
      padding: 5px 10px;
      border-radius: 15px;
      font-size: 12px;
      margin-left: 10px;
    }
    
    .course-info {
      margin-bottom: 15px;
    }
    
    .course-name {
      font-size: 16px;
      color: var(--dark-green);
      margin-bottom: 5px;
      font-weight: 600;
    }
    
    .course-description {
      color: var(--text-dark);
      font-size: 14px;
    }
    
    .teachers-section h4 {
      font-size: 16px;
      color: var(--dark-green);
      margin-bottom: 10px;
      font-weight: 600;
    }
    
    .teachers-list {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
    }
    
    .teacher-name {
      background-color: var(--light-green);
      color: var(--dark-green);
      padding: 5px 10px;
      border-radius: 15px;
      font-size: 12px;
    }
    
    .book-now-button {
      background-color: var(--dark-green);
      color: var(--text-light);
      border: none;
      padding: 12px 20px;
      border-radius: 8px;
      font-size: 16px;
      font-weight: 600;
      cursor: pointer;
      transition: all 0.3s ease;
      width: 100%;
      margin-top: auto;
    }
    
    .book-now-button:hover {
      background-color: var(--medium-green);
    }
    
    .floating-chat-button {
      position: fixed;
      bottom: 30px;
      right: 30px;
      background-color: var(--dark-green);
      color: var(--text-light);
      border: none;
      border-radius: 50%;
      width: 60px;
      height: 60px;
      font-size: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      box-shadow: 0 10px 30px var(--shadow-color);
      transition: all 0.3s ease;
    }
    
    .floating-chat-button:hover {
      transform: scale(1.1);
      background-color: var(--medium-green);
    }

    .custom-modal-overlay {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.6);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;
    }
    
    .custom-modal {
      background: white;
      border-radius: 8px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      width: 90%;
      max-width: 400px;
      outline: none;
    }
    
    .modal-content {
      padding: 1.5rem;
    }
    
    .modal-title {
      color: #333;
      margin-bottom: 1rem;
      font-size: 1.25rem;
      text-align: center;
    }
    
    .modal-message {
      color: #666;
      margin-bottom: 1.5rem;
      font-size: 0.9rem;
      line-height: 1.4;
      text-align: center;
    }
    
    .modal-buttons {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }
    
    .modal-button {
      padding: 0.75rem 1rem;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-size: 1rem;
      transition: background-color 0.3s;
      width: 100%;
    }
    
    .modal-button.go-to-wallet {
      background-color: #4CAF50;
      color: white;
    }
    
    .modal-button.go-to-wallet:hover {
      background-color: #45a049;
    }
    
    .modal-button.close {
      background-color: #f44336;
      color: white;
    }
    
    .modal-button.close:hover {
      background-color: #da190b;
    }
    
    @media (min-width: 768px) {
      .modal-content {
        padding: 2rem;
      }
    
      .modal-title {
        font-size: 1.5rem;
      }
    
      .modal-message {
        font-size: 1rem;
      }
    
      .modal-buttons {
        flex-direction: row;
        justify-content: flex-end;
      }
    
      .modal-button {
        width: auto;
      }
    }
    
    .modal-button.go-to-wallet {
      background-color: #4CAF50;
      color: white;
    }
    
    .modal-button.go-to-wallet:hover {
      background-color: #45a049;
    }
    
    .modal-button.close {
      background-color: #f44336;
      color: white;
    }
    
    .modal-button.close:hover {
      background-color: #da190b;
    }

    .fc-content.booked-slot {
      background-color: #FF0000;
      color: white;
    }
    
    .fc-content.booked-slot input[type="checkbox"] {
      display: none;
    }

    .fc-content.booked-slot {
      background-color: #FF0000;
      color: white;
      cursor: not-allowed;
    }
    
    .fc-content.available-slot {
      cursor: pointer;
    }
    
    .fc-content.booked-slot input[type="checkbox"] {
      display: none;
    }

    /* -------------------------------------------------------Teacher Profile Calender*/

    .fc-content.booked {
      background-color: #FF0000;
      color: white;
    }
    
    .fc-content.available {
      background-color: #00FF00;
      color: black;
    }
    
    .fc-content.selected {
      background-color: #4d4d29;
      color: black;
    }

        /* -------------------------------------------------------Currency Selector*/



        .package-price {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        
        .currency-selector {
          display: flex;
          align-items: center;
          margin-left: 10px;
        }
        
        .globe-icon {
          margin-right: 5px;
        }
        
        .currency-select {
          padding: 2px 5px;
          border-radius: 4px;
          border: 1px solid #ccc;
          background-color: white;
          font-size: 12px;
        }

 /* -------------------------------------------------------Student Profile Popup*/

 /* Compact Modal Styles */
.language-modal-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.language-modal-content {
  background-color: #fff;
  width: 300px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.language-modal-content h5 {
  font-size: 18px;
  color: #107314;
  margin-bottom: 15px;
  font-weight: 600;
}

.language-modal-content p {
  font-size: 14px;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.language-modal-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.language-modal-buttons button {
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 45%;
}

.language-modal-buttons .btn-cancel {
  background-color: #e74c3c;
  color: white;
}

.language-modal-buttons .btn-cancel:hover {
  background-color: #c0392b;
}

.language-modal-buttons .btn-confirm {
  background-color: #107314;
  color: white;
}

.language-modal-buttons .btn-confirm:hover {
  background-color: #0a5a0e;
}

.cookie-consent-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.cookie-consent-modal {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  max-width: 500px;
}

.cookie-consent-buttons {
  margin-top: 20px;
}

.cookie-consent-buttons button {
  margin: 0 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.cookie-consent-buttons button:first-child {
  background-color: #4CAF50;
  color: white;
}

.cookie-consent-buttons button:last-child {
  background-color: #f44336;
  color: white;
}




.calendar-container {
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
}

.fc {
  font-size: 14px;
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 0.5em;
  flex-wrap: wrap;
}

.fc .fc-toolbar-title {
  font-size: 1.2em;
}

.fc .fc-button {
  padding: 0.3em 0.5em;
  font-size: 0.9em;
}

.fc .fc-daygrid-day-number {
  font-size: 0.9em;
}

.fc .fc-daygrid-day-top {
  flex-direction: row;
}

.fc .fc-daygrid-day-events {
  min-height: auto !important;
}

.fc-content {
  font-size: 0.8em;
  display: flex;
  align-items: center;
}

.fc-content input[type="checkbox"] {
  margin-right: 4px;
}

.event-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 767px) {
  .fc .fc-toolbar {
    display: flex;
    flex-direction: column;
  }

  .fc .fc-toolbar-title {
    font-size: 1em;
    margin: 0.5em 0;
  }

  .fc .fc-button {
    font-size: 0.8em;
  }

  .fc .fc-daygrid-day-number {
    font-size: 0.8em;
  }

  .fc-content {
    font-size: 0.7em;
  }

  .fc .fc-daygrid-event {
    white-space: normal;
  }
}

 /* -------------------------------------------------------Payment Failed page*/

 .payment-failed-message {
  text-align: center;
  padding: 2rem;
  background-color: #fff5f5;
  border-radius: 8px;
  margin-bottom: 2rem;
}

.payment-failed-message .failed-icon {
  color: #e53e3e;
  margin-bottom: 1rem;
}

.payment-failed-message h2 {
  color: #e53e3e;
  margin-bottom: 1rem;
}

.payment-failed-message p {
  color: #4a5568;
  margin-bottom: 0.5rem;
}

.status-failed {
  background-color: #fff5f5;
  color: #e53e3e;
}

.btn-primary {
  background-color: #4299e1;
  color: white;
}

.btn-secondary {
  background-color: #a0aec0;
  color: white;
}

.btn:hover {
  opacity: 0.9;
}


/* -------------------------------------------------------Our Teacher Slider*/

@keyframes ot-fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes ot-shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.ot-our-teachers {
  padding: 4rem 0;
  background: linear-gradient(135deg, #f0f7f0 0%, #e6f3e6 100%);
  overflow: hidden;
}

.ot-section-header {
  text-align: center;
  margin-bottom: 3rem;
}

.ot-animate-text {
  opacity: 0;
  animation: ot-fadeInUp 0.6s ease forwards;
}

.ot-section-header h2 {
  font-size: 2.5rem;
  color: #107314;
  margin-bottom: 0.5rem;
  animation-delay: 0.2s;
}

.ot-section-header p {
  font-size: 1.1rem;
  color: #2c5e2e;
  animation-delay: 0.4s;
}

.ot-teacher-slider {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

.ot-teacher-card {
  padding: 1rem;
  opacity: 0;
  animation: ot-fadeInUp 0.6s ease forwards;
  animation-delay: 0.6s;
}

.ot-teacher-card-inner {
  background-color: white;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(16, 115, 20, 0.1);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.ot-teacher-card-inner:hover {
  transform: translateY(-10px) scale(1.02);
  box-shadow: 0 15px 40px rgba(16, 115, 20, 0.2);
}

.ot-teacher-image-container {
  position: relative;
  padding-top: 100%;
  overflow: hidden;
}

.ot-teacher-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.ot-teacher-card-inner:hover .ot-teacher-image {
  transform: scale(1.1);
}

.ot-teacher-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(16, 115, 20, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.ot-teacher-card-inner:hover .ot-teacher-overlay {
  opacity: 1;
}

.ot-view-profile {
  color: white;
  text-decoration: none;
  padding: 10px 20px;
  border: 2px solid white;
  border-radius: 25px;
  font-weight: bold;
  transition: all 0.3s ease;
}

.ot-view-profile:hover {
  background-color: white;
  color: #107314;
}

.ot-teacher-info {
  padding: 1.5rem;
  text-align: center;
}

.ot-teacher-name {
  font-size: 1.3rem;
  color: #107314;
  margin: 0 0 0.5rem;
  transition: color 0.3s ease;
}

.ot-teacher-card-inner:hover .ot-teacher-name {
  color: #0a5a0e;
}

.ot-teacher-title {
  font-size: 0.9rem;
  color: #2c5e2e;
  margin: 0;
}

/* Slider customization */
.ot-our-teachers .slick-dots {
  bottom: -40px;
}

.ot-our-teachers .slick-dots li button:before {
  font-size: 12px;
  color: #a8d5aa;
}

.ot-our-teachers .slick-dots li.slick-active button:before {
  color: #107314;
}

.ot-custom-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  background-color: rgba(16, 115, 20, 0.8);
  border-radius: 50%;
  display: flex !important;
  align-items: center;
  justify-content: center;
  z-index: 1;
  transition: all 0.3s ease;
  cursor: pointer;
}

.ot-custom-arrow:hover {
  background-color: rgba(10, 90, 14, 1);
}

.ot-custom-arrow svg {
  width: 24px;
  height: 24px;
  color: white;
  transition: transform 0.3s ease;
}

.ot-prev-arrow {
  left: -20px;
}

.ot-next-arrow {
  right: -20px;
}

.ot-next-arrow svg {
  transform: rotate(180deg);
}

.ot-custom-arrow:hover svg {
  transform: scale(1.2);
}

.ot-next-arrow:hover svg {
  transform: rotate(180deg) scale(1.2);
}

@media (max-width: 768px) {
  .ot-prev-arrow {
    left: -10px;
  }

  .ot-next-arrow {
    right: -10px;
  }
}

/* Loading shimmer effect */
.ot-teacher-card-inner::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(to right, #f0f7f0 0%, #e6f3e6 20%, #f0f7f0 40%, #f0f7f0 100%);
  background-size: 2000px 100%;
  animation: ot-shimmer 1.5s infinite linear;
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: -1;
}

.ot-teacher-card-inner.ot-loading::before {
  opacity: 1;
}

/* Hover effect for entire card */
.ot-teacher-card-inner::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: radial-gradient(circle at center, rgba(16, 115, 20, 0.1) 0%, rgba(16, 115, 20, 0) 80%);
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
}

.ot-teacher-card-inner:hover::after {
  opacity: 1;
}

.invoice-container {
  width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f8f9fa;
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.invoice-header, .invoice-details {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.invoice-header div, .invoice-details div {
  width: 48%;
}

.invoice-header .invoice-number {
  font-size: 1.5rem;
  font-weight: bold;
}

.invoice-section-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
  border-bottom: 2px solid #000;
  padding-bottom: 5px;
}

.cd-read-more-btn {
  background: none;
  border: none;
  color: #107314;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  text-decoration: underline;
  padding: 0;
  margin-top: 10px;
}

.cd-read-more-btn:hover {
  color: #0a5a0e;
}

/* Adjusted the width of the invoice content */
.invoice-content {
  width: 2000px; /* Increased width for the invoice content */
  margin: 0 auto;
}

.invoice-table {
  width: 100%;
  border-collapse: collapse;
}

.invoice-table th, .invoice-table td {
  border: 1px solid #e1e1e1;
  padding: 10px;
  text-align: left;
}

.invoice-table th {
  background-color: #343a40;
  color: white;
}

.invoice-summary {
  margin-top: 20px;
}

.invoice-summary table {
  width: 100%;
  border-collapse: collapse;
}

.invoice-summary td {
  padding: 10px;
  border: 1px solid #e1e1e1;
}

.footer-text {
  font-size: 0.9rem;
  text-align: center;
}

.footer-text .bold-text {
  font-weight: bold;
}

.download-button {
  margin: 20px 0;
  display: flex;
  justify-content: center;
}

.download-button button {
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  background-color: #28a745;
  color: white;
  cursor: pointer;
}

.download-button button:hover {
  background-color: #218838;
}

/* Student Dash Clock UTC */

.timezone-clock {
  display: flex;
  align-items: center;
  background-color: #f0f4f8;
  border-radius: 12px;
  padding: 8px 12px;
  margin-bottom: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 240px;
}

.digital-clock {
  background-color: #2c3e50;
  border-radius: 6px;
  padding: 4px 8px;
  margin-right: 12px;
}

.digital-clock .time {
  font-family: 'Roboto Mono', monospace;
  font-size: 1.1em;
  font-weight: 500;
  color: #ecf0f1;
}

.timezone-info {
  display: flex;
  flex-direction: column;
}

.timezone-info .timezone {
  font-size: 0.85em;
  font-weight: 500;
  color: #34495e;
}

.timezone-info .utc-time {
  font-size: 0.75em;
  color: #7f8c8d;
  margin-top: 2px;
}

.Admin-Dashboard_selectors {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.timezone-selector {
  width: 200px;
}



 /* -------------------------------------------------------Chat page*/

/* Add this to your index.css or a separate styles file */

/* Custom scrollbar */
.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #666;
}

/* Message animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-in {
  animation: fadeIn 0.3s ease-out forwards;
}

/* Message bubble styles */
.message-bubble {
  position: relative;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.message-bubble::before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border: 8px solid transparent;
}

.message-bubble.bg-gradient-to-r::before {
  right: -8px;
  top: 10px;
  border-left-color: #3b82f6;
}

.message-bubble.bg-gray-100::before {
  left: -8px;
  top: 10px;
  border-right-color: #f3f4f6;
}

/* Custom icons */
.close-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  position: relative;
}

.close-icon::before,
.close-icon::after {
  content: '';
  position: absolute;
  width: 2px;
  height: 16px;
  background-color: white;
  top: 50%;
  left: 50%;
}

.close-icon::before {
  transform: translate(-50%, -50%) rotate(45deg);
}

.close-icon::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.minimize-icon {
  display: inline-block;
  width: 16px;
  height: 2px;
  background-color: white;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.send-icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  position: relative;
  transform: rotate(45deg);
}

.send-icon::before {
  content: '';
  position: absolute;
  width: 14px;
  height: 2px;
  background-color: #3b82f6;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.send-icon::after {
  content: '';
  position: absolute;
  width: 8px;
  height: 2px;
  background-color: #3b82f6;
  top: 4px;
  right: 4px;
  transform: rotate(90deg);
}

/* Button hover effects */
.close-btn:hover .close-icon::before,
.close-btn:hover .close-icon::after {
  background-color: #fce7e7;
}

.minimize-btn:hover .minimize-icon {
  background-color: #e5e7eb;
}

.send-btn:hover .send-icon::before,
.send-btn:hover .send-icon::after {
  background-color: #2563eb;
}

/* Custom input focus styles */
input:focus {
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.2);
}

/* Message typing animation */
@keyframes typing {
  0% { opacity: .2; }
  20% { opacity: 1; }
  100% { opacity: .2; }
}

.typing-indicator span {
  animation: typing 1.4s infinite;
  animation-fill-mode: both;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #3b82f6;
  display: inline-block;
  margin: 0 1px;
}

.typing-indicator span:nth-child(2) {
  animation-delay: .2s;
}

.typing-indicator span:nth-child(3) {
  animation-delay: .4s;
}


.styled-container {
  padding: 24px;
  max-width: 1200px;
  margin: 0 auto;
}

/* ------------------------------Weekly Calendar----------------------------- */

/* Main Container */
.calendar-container {
  background: white;
  padding: 32px;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  max-width: 1200px;
  margin: 0 auto;
}

/* Time Slots Grid */
.time-slots-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 12px;
  margin-top: 16px;
  padding: 16px;
  background-color: #f8f9fa;
  border-radius: 8px;
  border: 1px solid #e8e8e8;
}

/* Individual Time Slot */
.slot-checkbox {
  width: 100%;
  margin: 0;
  padding: 10px 12px;
  background-color: white;
  border-radius: 6px;
  display: flex;
  align-items: center;
  border: 1px solid #f0f0f0;
  transition: all 0.3s ease;
}

.slot-checkbox:hover {
  background-color: #fafafa;
  transform: translateY(-1px);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.04);
}

/* Booked Slots */
.slot-checkbox.booked-slot {
  background-color: #fff2f0;
  border: 1px solid #ffccc7;
  opacity: 0.9;
}

/* Checkbox Styling */
.slot-checkbox .ant-checkbox {
  margin-right: 10px;
}

.slot-checkbox .ant-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.35);
}

/* Booked Text */
.booked-text {
  color: #ff4d4f;
  font-weight: 500;
  font-size: 0.9rem;
}

/* Time Range Controls */
.time-range-controls {
  display: flex;
  align-items: center;
  margin-left: 16px;
  padding: 8px 12px;
  background: #f8f9fa;
  border-radius: 6px;
  border: 1px solid #e8e8e8;
}

.time-range-controls .ant-select {
  min-width: 100px;
}

.time-range-controls span {
  color: #666;
  margin: 0 12px;
}

.time-range-controls .ant-btn {
  margin-left: 12px;
  background: #1890ff;
  border-color: #1890ff;
  border-radius: 4px;
  height: 32px;
  padding: 0 16px;
  transition: all 0.3s ease;
}

.time-range-controls .ant-btn:hover {
  background: #40a9ff;
  border-color: #40a9ff;
  transform: translateY(-1px);
  box-shadow: 0 2px 6px rgba(24, 144, 255, 0.2);
}

/* Day Controls */
.day-controls {
  display: flex;
  align-items: center;
  padding: 16px;
  background: #fafafa;
  border-radius: 8px;
  margin-bottom: 16px;
  border: 1px solid #f0f0f0;
}

.day-label {
  font-weight: 500;
  margin: 0 12px;
  color: #262626;
}

.day-label-disabled {
  color: #bfbfbf;
  margin: 0 12px;
}

/* Copy Controls */
.copy-controls {
  display: flex;
  align-items: center;
  margin-left: 16px;
  padding: 8px 12px;
  background: #f8f9fa;
  border-radius: 6px;
  border: 1px solid #e8e8e8;
}

.copy-controls span {
  color: #666;
  margin-right: 8px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .time-slots-grid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 8px;
    padding: 12px;
  }

  .day-controls {
    flex-direction: column;
    align-items: flex-start;
  }

  .time-range-controls,
  .copy-controls {
    margin-left: 0;
    margin-top: 12px;
    width: 100%;
  }
}

@keyframes float {
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
}

@keyframes spotlight-pulse {
  0% { box-shadow: 0 0 0 0 rgba(99, 102, 241, 0.4); }
  70% { box-shadow: 0 0 0 10px rgba(99, 102, 241, 0); }
  100% { box-shadow: 0 0 0 0 rgba(99, 102, 241, 0); }
}

.react-joyride__tooltip {
  animation: float 3s ease-in-out infinite !important;
}

.react-joyride__spotlight {
  animation: spotlight-pulse 2s infinite !important;
}

.react-joyride__overlay {
  transition: background-color 0.4s ease !important;
}

.react-joyride__tooltip button:hover {
  transform: translateY(-2px) !important;
  box-shadow: 0 10px 15px -3px rgba(99, 102, 241, 0.4) !important;
}

.emoji-bounce {
  display: inline-block;
  animation: bounce 1s infinite;
}

@keyframes bounce {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}


/*-----------------------------Add book free trail---------------------------*/
.btl-container {
  min-height: 100vh;
  background-color: #f5f5f5;
}

.btl-hero {
  background: linear-gradient(135deg, #6B73FF 0%, #000DFF 100%);
  padding: 80px 0;
  text-align: center;
  color: white;
  position: relative;
  overflow: hidden;
}

.btl-hero::before {
  content: '';
  position: absolute;
  width: 200%;
  height: 200%;
  top: -50%;
  left: -50%;
  background: radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%);
  animation: rotate 20s linear infinite;
}

.btl-hero-title {
  font-size: 3rem !important;
  margin-bottom: 1rem !important;
  position: relative;
  animation: fadeIn 1s ease-in-out;
}

.btl-hero-subtitle {
  margin-bottom: 2rem !important;
  position: relative;
  animation: fadeIn 1.5s ease-in-out;
}

.btl-feature-chips {
  display: flex;
  gap: 1rem;
  justify-content: center;
  position: relative;
}

.btl-chip {
  background-color: white !important;
  color: #1976d2 !important;
  padding: 1rem !important;
  transition: transform 0.3s ease;
}

.btl-chip:hover {
  transform: scale(1.05);
}

.btl-main-content {
  margin-top: -40px;
  padding-bottom: 40px;
  position: relative;
}

.btl-teachers-section {
  padding: 2rem;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  animation: slideIn 1s ease-in-out;
}

.btl-section-title {
  text-align: center;
  margin-bottom: 2rem !important;
  color: #333;
}

.btl-teacher-card {
  padding: 1rem;
}

.btl-card-inner {
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  overflow: hidden;
  border-radius: 12px;
}

.btl-card-inner:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 20px rgba(0,0,0,0.2);
}

.btl-teacher-name {
  margin-top: 1rem !important;
}

.btl-teacher-title {
  color: #666;
  margin-bottom: 1rem !important;
}

.btl-book-button {
  margin-top: 1rem !important;
  text-transform: none !important;
  transition: background-color 0.3s ease;
}

.btl-book-button:hover {
  background-color: #1976d2;
  color: white;
}

.btl-selected-teacher {
  height: 100%;
}

.btl-teacher-description {
  margin: 1rem 0;
  line-height: 1.6;
}

.btl-video-container {
  position: relative;
  padding-top: 56.25%;
  margin-top: 1rem;
}

.btl-video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.btl-booking-section {
  height: 100%;
}

.btl-custom-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;
  background: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: transform 0.3s ease;
}

.btl-custom-arrow:hover {
  transform: translateY(-50%) scale(1.1);
}

.btl-next-arrow {
  right: -20px;
}

.btl-prev-arrow {
  left: -20px;
}

@keyframes rotate {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideIn {
  from { transform: translateX(-100%); }
  to { transform: translateX(0); }
}

@media (max-width: 600px) {
  .btl-hero-title {
    font-size: 2rem !important;
  }
  
  .btl-feature-chips {
    flex-direction: column;
    align-items: center;
  }
}

/* Sidebar Styles */
.ps-sidebar-root {
  border: none !important;
  min-height: 100vh;
}

.ps-sidebar-container {
  background: white !important;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.05) !important;
}

.ps-menu-button {
  padding: 8px 16px !important;
  margin: 4px 8px !important;
  border-radius: 8px !important;
}

.ps-menu-button:hover {
  background-color: #f1f5f9 !important;
  color: #0369a1 !important;
}

.ps-menu-button.ps-active {
  background-color: #e0f2fe !important;
  color: #0284c7 !important;
}

.ps-menu-label {
  font-size: 0.9375rem !important;
  font-weight: 500 !important;
}

.ps-submenu-content {
  background-color: transparent !important;
}

.section-title {
  color: #94a3b8;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  padding: 8px 16px;
  margin-top: 16px;
}


.payments-table .ant-table {
  background: transparent;
}

.payments-table .ant-table-cell {
  background: transparent !important;
  border-bottom: 1px solid rgba(229, 231, 235, 0.5);
  padding: 16px;
}

.payments-table .ant-table-row:hover .ant-table-cell {
  background: rgb(243 244 246 / 0.9) !important;
}

.ant-btn {
  border-radius: 12px;
  height: 40px;
  padding: 0 20px;
  font-weight: 500;
}

.ant-input-search .ant-input {
  border-radius: 12px;
}

.ant-select-selector {
  border-radius: 12px !important;
  height: 40px !important;
  padding: 4px 16px !important;
}

.ant-select-selection-item {
  line-height: 32px !important;
}

.courses-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 30px;
  justify-items: center;
  margin-top: 40px;
}

.course-card {
  width: 320px;
  height: 450px;
  perspective: 1500px;
  cursor: pointer;
}

.course-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: left;
  transition: transform 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transform-style: preserve-3d;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
}

.course-card:hover .course-card-inner {
  transform: rotateY(180deg);
}

.course-card-front, .course-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 16px;
  overflow: hidden;
}

.course-card-front {
  background: #ffffff;
  border: 1px solid rgba(79, 70, 229, 0.1);
}

.course-card-back {
  background: linear-gradient(145deg, #f7f7ff, #ffffff);
  color: #1e1b4b;
  transform: rotateY(180deg);
  border: 1px solid rgba(79, 70, 229, 0.15);
  display: flex;
  flex-direction: column;
}

.image-container {
  position: relative;
  width: 100%;
  height: 180px;
  overflow: hidden;
}

.course-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.course-card:hover .course-image {
  transform: scale(1.05);
}

.package-label {
  position: absolute;
  bottom: 0;
  left: 0;
  background: rgba(67, 56, 202, 0.9);
  color: white;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 500;
  border-top-right-radius: 8px;
}

.discount-badge {
  position: absolute;
  top: 12px;
  right: 12px;
  background: linear-gradient(45deg, #ff5757, #ff2f2f);
  color: white;
  padding: 4px 10px;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 600;
  z-index: 2;
  box-shadow: 0 2px 8px rgba(255, 47, 47, 0.3);
  transform: rotate(3deg);
}

.card-content-front {
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: calc(100% - 180px);
}

.card-content-back {
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.course-title {
  margin: 0 0 12px 0;
  font-size: 18px;
  font-weight: 600;
  color: #2d2b57;
  line-height: 1.3;
}

.back-title {
  font-size: 20px;
  font-weight: 600;
  color: #4338ca;
  margin: 0 0 15px 0;
  border-bottom: 2px solid rgba(79, 70, 229, 0.2);
  padding-bottom: 10px;
}

.card-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  font-size: 14px;
  color: #6b7280;
}

.lessons-info {
  display: flex;
  align-items: center;
}

.lessons-count {
  font-weight: 500;
}

.teacher-count {
  font-size: 13px;
  color: #6b7280;
  background: rgba(79, 70, 229, 0.08);
  padding: 3px 8px;
  border-radius: 4px;
}

.price-section {
  margin-top: auto;
  padding-top: 15px;
  border-top: 1px solid rgba(79, 70, 229, 0.1);
}

.course-price {
  margin: 0;
  font-size: 22px;
  color: #4338ca;
  font-weight: 700;
}

.per-lesson {
  font-size: 14px;
  color: #6b7280;
  font-weight: 400;
}

.total-price {
  font-size: 13px;
  color: #6b7280;
  margin-top: 2px;
}

.card-action {
  margin-top: 15px;
}

.view-details-btn {
  background: none;
  border: 1px solid #4338ca;
  color: #4338ca;
  padding: 8px 0;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  transition: all 0.3s ease;
  cursor: pointer;
}

.view-details-btn:hover {
  background: rgba(79, 70, 229, 0.05);
}

.description-container {
  flex-grow: 1;
  overflow-y: auto;
  margin-bottom: 15px;
  scrollbar-width: thin;
  scrollbar-color: #4338ca #e9e7fd;
}

.description-container::-webkit-scrollbar {
  width: 4px;
}

.description-container::-webkit-scrollbar-track {
  background: #e9e7fd;
  border-radius: 2px;
}

.description-container::-webkit-scrollbar-thumb {
  background: #4338ca;
  border-radius: 2px;
}

.course-description {
  font-size: 14px;
  color: #4b5563;
  line-height: 1.6;
  margin: 0;
}

.read-more {
  color: #4338ca;
  font-weight: 500;
}

.teachers-preview {
  margin-bottom: 20px;
}

.teachers-label {
  display: block;
  font-size: 14px;
  color: #4b5563;
  margin-bottom: 8px;
  font-weight: 500;
}

.teachers-list {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}

.teacher-badge {
  background: rgba(79, 70, 229, 0.1);
  color: #4338ca;
  padding: 4px 10px;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 500;
}

.teacher-badge.more {
  background: rgba(107, 114, 128, 0.1);
  color: #6b7280;
}

.enroll-btn {
  background: linear-gradient(135deg, #4f46e5, #4338ca);
  color: white;
  padding: 12px 0;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  border: none;
  cursor: pointer;
  box-shadow: 0 4px 12px rgba(79, 70, 229, 0.2);
  transition: all 0.3s ease;
  text-align: center;
}

.enroll-btn:hover {
  background: linear-gradient(135deg, #4338ca, #3730a3);
  transform: translateY(-2px);
  box-shadow: 0 6px 15px rgba(79, 70, 229, 0.3);
}

/* Animations */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.courses-grid .course-card {
  animation: fadeInUp 0.6s ease forwards;
  opacity: 0;
}

.courses-grid .course-card:nth-child(2) {
  animation-delay: 0.1s;
}

.courses-grid .course-card:nth-child(3) {
  animation-delay: 0.2s;
}

.courses-grid .course-card:nth-child(4) {
  animation-delay: 0.3s;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .courses-grid {
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  }
  
  .course-card {
    width: 280px;
    height: 420px;
  }
  
  .image-container {
    height: 160px;
  }
}
