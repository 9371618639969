.calendar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.calendar {
  max-width: 400px;
  margin-bottom: 20px;
}

.has-slots {
  background-color: #ffcccc; /* Highlight dates with time slots */
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex !important;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.modal h2 {
  margin-bottom: 10px;
  font-size: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: 1px;
  text-align: center;
}

.modal ul {
  list-style: none;
  padding: 0;
}

.modal li {
  margin-bottom: 5px;
  display: flex;
  gap: 10px;
  align-items: center;
}

.modal input[type="time"] {
  width: 100px;
  margin-right: 10px;
  margin-left: 10px;
}

.modal button  {
  padding: 8px 16px;
  margin-top: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* .modal button:not(:last-child) {
    margin-right: 10px;
  } */

.modal button:hover {
  background-color: #0056b3;
}
.delete-btn {
  background: transparent;
  border: none !important;
  text-align: center;
  background-color: unset !important;
  margin: 0 !important;
  padding: 0 !important;
}
.ant-modal-close {
  display: none;
}
.Add-btn {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 12px;
}
