.guide-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .guide-modal {
    background: #fff;
    border-radius: 16px;
    padding: 2rem;
    width: 90%;
    max-width: 460px;
    position: relative;
    box-shadow: 0 20px 60px rgba(0, 0, 0, 0.3);
    animation: slideUp 0.3s ease;
  }
  
  .close-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
    border: none;
    background: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #666;
  }
  
  .progress-bar {
    display: flex;
    justify-content: center;
    gap: 0.8rem;
    margin-bottom: 2rem;
  }
  
  .progress-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #e0e0e0;
    transition: all 0.3s ease;
  }
  
  .progress-dot.active {
    background: #007bff;
    transform: scale(1.2);
  }
  
  .progress-dot.completed {
    background: #28a745;
  }
  
  .step-content {
    text-align: center;
    margin-bottom: 2rem;
    min-height: 180px;
  }
  
  .step-icon {
    font-size: 3rem;
    margin-bottom: 1rem;
    animation: bounce 2s infinite;
  }
  
  .step-title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #333;
  }
  
  .step-description {
    color: #666;
  }
  
  /* Timezone prompt demo styles */
  .timezone-prompt-demo {
    margin-top: 1.5rem;
    background: #f8f9fa;
    border: 2px solid #e0e0e0;
    border-radius: 8px;
    padding: 1.5rem;
    position: relative;
    animation: highlight 2s infinite;
  }
  
  .demo-message {
    margin-bottom: 1rem;
    font-size: 0.9rem;
    color: #555;
  }
  
  .demo-buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }
  
  .demo-btn {
    padding: 0.5rem 1.5rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    transition: all 0.2s;
  }
  
  .demo-btn.highlight {
    background: #007bff;
    color: white;
    animation: pulse 1.5s infinite;
  }
  
  .demo-btn:not(.highlight) {
    background: #e0e0e0;
    color: #666;
  }
  
  .cursor-pointer {
    font-size: 1.5rem;
    position: absolute;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);
    animation: pointUp 1.5s infinite;
  }
  
  .button-container {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
  }
  
  .nav-button {
    padding: 0.8rem 1.5rem;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-weight: 500;
    transition: all 0.2s;
    flex: 1;
  }
  
  .nav-button.primary {
    background: #007bff;
    color: white;
  }
  
  .nav-button:not(.primary) {
    background: #f0f0f0;
    color: #333;
  }
  
  .nav-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  @keyframes slideUp {
    from { transform: translateY(20px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
  }
  
  @keyframes bounce {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-10px); }
  }
  
  @keyframes highlight {
    0%, 100% { border-color: #e0e0e0; }
    50% { border-color: #007bff; }
  }
  
  @keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.05); }
    100% { transform: scale(1); }
  }
  
  @keyframes pointUp {
    0%, 100% { transform: translateX(-50%) translateY(0); }
    50% { transform: translateX(-50%) translateY(-10px); }
  }

  .guidance-content {
    text-align: center;
    padding: 1rem;
  }
  
  .dropdown-indicator {
    position: relative;
    margin-top: 2rem;
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .arrow-pointer {
    display: flex;
    align-items: center;
    animation: pointLeft 1s infinite;
  }
  
  .arrow-pointer span {
    font-size: 2rem;
  }
  
  .arrow-line {
    width: 50px;
    height: 2px;
    background: #007bff;
    margin-left: 10px;
  }
  
  .mock-dropdown {
    padding: 8px 16px;
    border: 2px solid #007bff;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 200px;
    background: white;
  }
  
  .dropdown-arrow {
    color: #666;
    animation: bounce 1s infinite;
  }
  
  /* Add this to your existing CSS */
  .highlight-element {
    position: relative;
    z-index: 1001;
    animation: highlight-pulse 2s infinite;
  }
  
  @keyframes pointLeft {
    0%, 100% { transform: translateX(0); }
    50% { transform: translateX(-10px); }
  }
  
  @keyframes highlight-pulse {
    0%, 100% { box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.4); }
    50% { box-shadow: 0 0 0 10px rgba(0, 123, 255, 0); }
  }
  
  /* Make the select component visible through the overlay */
  .timezone-selector {
    position: relative;
    z-index: 1001;
  }
  
  /* Ensure the highlight doesn't interfere with clicking */
  .guide-overlay {
    pointer-events: none;
  }
  
  .guide-modal {
    pointer-events: all;
  }
  
  /* Additional animation for the dropdown */
  .timezone-selector.highlight-element .select__control {
    border-color: #007bff;
    transition: all 0.3s ease;
  }
  
  .timezone-selector.highlight-element::after {
    content: '';
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    border: 2px solid #007bff;
    border-radius: 6px;
    animation: highlight-border 2s infinite;
  }
  
  @keyframes highlight-border {
    0%, 100% { transform: scale(1); opacity: 1; }
    50% { transform: scale(1.05); opacity: 0.5; }
  }

  .timezone-status {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    padding: 1rem;
    background: #f8f9fa;
    border-radius: 8px;
    margin-top: 1rem;
  }
  
  .success-message {
    color: #28a745;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    animation: fadeIn 0.3s ease;
  }
  
  .pending-message {
    color: #6c757d;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .check-icon {
    font-size: 1.2rem;
    animation: bounceOnce 0.5s ease;
  }
  
  .pending-icon {
    font-size: 1.2rem;
    animation: rotate 2s linear infinite;
  }
  
  .nav-button.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    background: #e9ecef;
    pointer-events: none;
  }
  
  @keyframes bounceOnce {
    0% { transform: scale(0); }
    50% { transform: scale(1.2); }
    100% { transform: scale(1); }
  }
  
  @keyframes rotate {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  /* Make sure the timezone selector is clickable */
  .guide-overlay {
    pointer-events: none;
  }
  
  .guide-modal {
    pointer-events: all;
  }
  
  .timezone-selector {
    position: relative;
    z-index: 1002;
    pointer-events: all;
  }