@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');


.cd-container {
  max-width: 1200px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: var(--card-background);
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  background: linear-gradient(-45deg, #EEF2F7, #E6E9F0, #F0F4F8, #EAF0F6);
  background-size: 400% 400%;
  animation: cdGradientBG 15s ease infinite;
}

.cd-main-content {
  display: flex;
  gap: 3rem;
  margin-bottom: 3rem;
  align-items: center;
}

.cd-image-wrapper {
  flex: 1;
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.cd-image-wrapper:hover {
  transform: scale(1.02);
}

.cd-course-image {
  width: 100%;
  height: auto;
  display: block;
  transition: transform 0.3s ease;
}

.cd-image-wrapper:hover .cd-course-image {
  transform: scale(1.1);
}

.cd-info-block {
  flex: 1;
}

.cd-course-title {
  font-size: 2.8rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
  position: relative;
  padding-bottom: 0.5rem;
}

.cd-course-title::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50px;
  height: 4px;
  background-color: var(--secondary-color);
  transition: width 0.3s ease;
}

.cd-course-title:hover::after {
  width: 100px;
}

.cd-course-description {
  font-size: 1.1rem;
  color: #666;
  line-height: 1.8;
  margin-bottom: 1.5rem;
}

.cd-teachers-section {
  margin-top: 4rem;
}

.cd-section-title {
  font-size: 2.2rem;
  color: var(--primary-color);
  margin-bottom: 2rem;
  text-align: center;
  position: relative;
}

.cd-section-title::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 4px;
  background-color: var(--secondary-color);
}

.cd-teachers-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 2rem;
}

.cd-teacher-card {
  background-color: var(--card-background);
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: all 0.3s ease;
  position: relative;
}

.cd-teacher-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.cd-teacher-image {
  width: 100%;
  height: 250px;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.cd-teacher-card:hover .cd-teacher-image {
  transform: scale(1.1);
}

.cd-teacher-info {
  padding: 1.5rem;
  text-align: center;
  position: relative;
  background: linear-gradient(to top, var(--card-background) 70%, transparent);
}

.cd-teacher-name {
  display: block;
  font-size: 1.3rem;
  color: var(--primary-color);
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-weight: 600;
  transition: color 0.3s ease;
}

.cd-teacher-name:hover {
  color: var(--secondary-color);
}

.cd-teacher-title {
  font-size: 1rem;
  color: #777;
  font-weight: 300;
}

.cd-error-message {
  background-color: #FFF5F5;
  border: 2px solid #FEB2B2;
  color: #C53030;
  padding: 1rem;
  border-radius: 10px;
  margin-bottom: 1rem;
  text-align: center;
  font-weight: 600;
  box-shadow: 0 5px 15px rgba(197, 48, 48, 0.1);
}

@media (max-width: 768px) {
  .cd-main-content {
    flex-direction: column;
  }

  .cd-image-wrapper,
  .cd-info-block {
    width: 100%;
  }

  .cd-course-title {
    font-size: 2.2rem;
  }

  .cd-teachers-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

@keyframes cdGradientBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}