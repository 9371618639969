.custom-calendar {
  width: 100%;
  max-width: 300px;
  font-family: Arial, sans-serif;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin: 0 auto;
  padding-bottom: 15px; /* Add padding at bottom */
}
  
  .calendar-header {
    background-color: #4bd94f;
    color: rgb(39, 193, 62);
    padding: 1rem;
  }
  
  .current-date {
    display: flex;
    align-items: baseline;
  }
  
  .day {
    font-size: 1.5rem;
    font-weight: bold;
    margin-right: 0.5rem;
  }
  
  .date {
    font-size: 3rem;
    font-weight: bold;
  }
  
  .month-year {
    font-size: 1.125rem;
    margin-top: 0.25rem;
  }
  
  .calendar-header-months {
    background-color: #4CAF50;
    color: white;
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
  }
  
  .calendar-body {
    padding: 1rem;
  }
  
  .month-navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.75rem;
  }
  
  .nav-button {
    background: none;
    border: none;
    font-size: 1.25rem;
    color: #333;
    cursor: pointer;
  }
  
  .current-month {
    font-size: 1rem;
    font-weight: bold;
  }
  
  .weekdays {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    text-align: center;
    font-weight: bold;
    color: #666;
    margin-bottom: 0.5rem;
  }
  
  .days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 0.25rem;
  }
  
  .calendar-day {
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.875rem;
    cursor: pointer;
  }
  
  .calendar-day.selected {
    background-color: #7aff73;
    color: white;
    border-radius: 50%;
  }
  
  .calendar-day.today {
    font-weight: bold;
  }
  
  .calendar-day.empty {
    visibility: hidden;
  }
  
  .calendar-footer {
    background-color: #4CAF50;
    color: white;
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
  }
  
  .calendar-day.available {
    background-color: #e6f7ff;
    font-weight: bold;
  }
  
  .td-time-slots {
    margin-top: 1rem;
  }
  
  .td-time-slots ul {
    list-style-type: none;
    padding: 0;
  }
  
  .td-time-slots li {
    margin-bottom: 0.25rem;
  }
  
  @media (max-width: 480px) {
    .custom-calendar {
      font-size: 14px;
    }
  
    .calendar-header {
      padding: 0.75rem;
    }
  
    .day {
      font-size: 1.25rem;
    }
  
    .date {
      font-size: 2.5rem;
    }
  
    .month-year {
      font-size: 1rem;
    }
  
    .calendar-body {
      padding: 0.75rem;
    }
  
    .nav-button {
      font-size: 1rem;
    }
  
    .current-month {
      font-size: 0.875rem;
    }
  
    .calendar-day {
      font-size: 0.75rem;
    }
  }

  /* Add to SmallCalendar.css */
  .calendar-time-slots {
    padding: 15px;
    border-top: 1px solid #eee;
    margin-top: 10px;
    background-color: white; /* Ensure background is visible */
}

.calendar-time-slots h4 {
    margin: 0 0 10px 0;
    color: #333;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
}

.time-slots-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-height: 150px;
    overflow-y: auto;
    padding: 0 10px;
}

.time-slot-item {
    padding: 8px 12px;
    background-color: #e6f7ff; /* Match available date color */
    border-radius: 4px;
    font-size: 13px;
    color: #333;
    cursor: pointer;
    transition: background-color 0.2s;
    text-align: center;
}

.time-slot-item:hover {
    background-color: #7aff73; /* Match selected date color */
    color: white;
}

/* Remove overflow hidden from calendar container */
.td-calendar-section {
    overflow: visible;
}

/* Add these new styles to your existing CSS */
.calendar-time-slots {
  padding: 15px;
  border-top: 1px solid #eee;
  margin-top: 10px;
  min-height: 50px; /* Ensure there's always space for the section */
}

.no-slots-message {
  text-align: center;
  color: #666;
  padding: 10px;
  font-size: 13px;
}

.time-slots-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 150px;
  overflow-y: auto;
}

.time-slot-item {
  padding: 8px 12px;
  background-color: #e6f7ff;
  border-radius: 4px;
  font-size: 13px;
  color: #333;
  cursor: pointer;
  transition: all 0.2s;
  text-align: center;
}

.time-slot-item:hover {
  background-color: #7aff73;
  color: white;
  transform: scale(1.02);
}

.calendar-day.available {
  background-color: #e6f7ff;
  font-weight: bold;
  position: relative;
}

.calendar-day.available:after {
  content: '';
  position: absolute;
  bottom: 2px;
  left: 50%;
  transform: translateX(-50%);
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #4CAF50;
}