/* AdminEditTeacherEditAvailability.css */

:root {
    --primary-color: #5bf15b;
    --success-color: #52c41a;
    --background-color: #f0f2f5;
    --white: #ffffff;
    --border-radius: 8px;
    --box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  }
  
  .styled-container {
    display: flex;
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
    /* Remove or comment out the next line */
    /* background-color: var(--background-color); */
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
  }
  
  .calendar-container {
    flex: 1;
    background-color: var(--white);
    padding: 2rem;
    border-radius: var(--border-radius);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .day-selection {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 1.5rem;
  }
  
  .day-switch {
    display: flex;
    align-items: center;
    margin-right: 1rem;
    margin-bottom: 0.5rem;
  }
  
  .day-switch-toggle {
    margin-right: 0.5rem;
  }
  
  /* Custom styles for Ant Design Switch */
  .ant-switch-checked {
    background-color: #52c41a !important; /* Green color */
  }
  
  .day-label,
  .day-label-disabled {
    padding: 0.25rem 0.5rem;
    transition: all 0.3s ease;
    background: none; /* Remove background */
  }
  
  .day-label {
    color: #000000; /* Black color for selected days */
    font-weight: bold;
  }
  
  .day-label-disabled {
    color: #999; /* Grey color for unselected days */
  }
  
  .mini-calendar-switch {
    margin: 1.5rem 0;
  }
  
  .action-buttons {
    display: flex;
    gap: 1rem;
    margin-top: 1.5rem;
  }
  
  .select-year-button,
  .set-availability-button {
    flex: 1;
  }
  
  .set-availability-button {
    background-color: var(--success-color);
    border-color: var(--success-color);
  }
  
  .set-availability-button:hover {
    background-color: #389e0d;
    border-color: #389e0d;
  }
  
  .modal-content {
    max-height: 70vh;
    overflow-y: auto;
  }
  
  .timezone-selector {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
  }
  
  .timezone-selector label {
    margin-right: 1rem;
  }
  
  .day-availability {
    margin-bottom: 1.5rem;
    padding: 1rem;
    background-color: #fafafa;
    border-radius: 4px;
  }
  
  .time-slots {
    margin-top: 1rem;
  }
  
  .time-slot {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
  }
  
  .edit-button,
  .delete-button,
  .add-slot-button {
    margin-left: 0.5rem;
  }
  
  .mini-calendar-container {
    width: 300px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }

  .calendar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #f0f0f0;
  }
  
  .calendar-header button {
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    color: #333;
  }
  
  .calendar-header h3 {
    margin: 0;
    font-size: 16px;
    color: #333;
  }
  
  .calendar-days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    padding: 10px;
    background-color: #f8f8f8;
  }
  
  .calendar-day-header {
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    color: #666;
  }
  
  .calendar-body {
    padding: 10px;
  }
  
  .calendar-row {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 2px;
    margin-bottom: 2px;
  }
  
  .calendar-day {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    font-size: 14px;
    cursor: pointer;
    border-radius: 50%;
    transition: all 0.2s ease;
  }
  
  .calendar-day:hover {
    background-color: #f0f0f0;
  }
  
  .calendar-day.disabled {
    color: #ccc;
  }
  
  .calendar-day.today {
    background-color: #e6f7ff;
    font-weight: bold;
  }
  
  .calendar-day.selected {
    background-color: #1890ff;
    color: white;
  }
  
  .calendar-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 0.25rem;
  }
  
  .calendar-day {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2rem;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .calendar-day.selected {
    background-color: var(--success-color);
    color: var(--white);
    border-color: var(--success-color);
  }
  
  .calendar-day:hover {
    background-color: #f5f5f5;
  }
  
  .calendar-day.selected:hover {
    background-color: #389e0d;
  }
  
  .calendar-day:not(.current-month) {
    opacity: 0.5;
  }
  
  .custom-day {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .custom-day.selected {
    background-color: var(--success-color);
    color: var(--white);
  }
  
  .selected-dates-display {
    margin-top: 1.5rem;
    max-height: 200px;
    overflow-y: auto;
  }
  
  .selected-dates-display .ant-checkbox-wrapper {
    display: block;
    margin-bottom: 0.5rem;
  }
  
  /* Custom styles for react-date-range */
  .rdrCalendarWrapper {
    font-size: 16px;
    width: 100%;
    background-color: var(--white);
  }
  
  .rdrDateDisplayWrapper,
  .rdrDefinedRangesWrapper,
  .rdrDateRangePickerWrapper {
    background-color: var(--white);
  }
  
  .rdrDayDisabled,
  .rdrDayPassive {
    background-color: var(--white);
  }
  
  .rdrDayToday .rdrDayNumber span:after {
    background: var(--primary-color);
  }
  
  .rdrDayToday:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span:after,
  .rdrDayToday:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span:after,
  .rdrDayToday:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span:after,
  .rdrDayToday:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span:after {
    background: var(--white);
  }
  
  .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
    color: var(--primary-color);
  }
  
  .rdrDayStartPreview,
  .rdrDayInPreview,
  .rdrDayEndPreview {
    border: 1px solid var(--primary-color);
    background-color: rgba(24, 144, 255, 0.1);
  }
  
  .rdrDayStartPreview.rdrDayHovered,
  .rdrDayInPreview.rdrDayHovered,
  .rdrDayEndPreview.rdrDayHovered {
    background-color: rgba(24, 144, 255, 0.2);
  }
  
  .rdrDateRangeWrapper .rdrInRange {
    background-color: rgba(24, 144, 255, 0.1);
  }
  
  .rdrDateRangeWrapper .rdrStartEdge,
  .rdrDateRangeWrapper .rdrEndEdge {
    background-color: var(--primary-color);
    color: var(--white);
  }
  
  .rdrDateRangeWrapper .rdrDayStartPreview,
  .rdrDateRangeWrapper .rdrDayInPreview,
  .rdrDateRangeWrapper .rdrDayEndPreview {
    border: 1px solid var(--primary-color);
    background-color: rgba(24, 144, 255, 0.1);
  }
  
  .rdrDateRangeWrapper .rdrDayHovered {
    background-color: rgba(24, 144, 255, 0.2);
  }
  
  @media (max-width: 768px) {
    .styled-container {
      flex-direction: column;
    }
  
    .calendar-container {
      margin-right: 0;
      margin-bottom: 1.5rem;
    }
  
    .action-buttons {
      flex-direction: column;
    }
  }

  /* Add these animation keyframes at the end of your CSS file */

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  @keyframes slideInFromRight {
    from { transform: translateX(50px); opacity: 0; }
    to { transform: translateX(0); opacity: 1; }
  }
  
  @keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.05); }
    100% { transform: scale(1); }
  }
  
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% { transform: translateY(0); }
    40% { transform: translateY(-30px); }
    60% { transform: translateY(-15px); }
  }
  
  /* Now, let's add these animations to specific elements */
  
  .styled-container {
    animation: fadeIn 0.5s ease-out;
  }
  
  .calendar-container {
    animation: slideInFromRight 0.5s ease-out;
  }
  
  .day-switch {
    transition: all 0.3s ease;
  }

  .calendar-day.available {
    background-color: yellow;
    cursor: pointer;
  }

  .calendar-day.selected {
    background-color: green !important;
    color: white !important;
  }
  
  .day-switch:hover {
    transform: scale(1.05);
  }
  
  .ant-switch-checked {
    animation: pulse 0.5s;
  }
  
  .action-buttons button {
    transition: all 0.3s ease;
  }
  
  .action-buttons button:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .mini-calendar-container {
    animation: fadeIn 0.5s ease-out;
  }
  
  .calendar-day {
    transition: all 0.2s ease;
  }
  
  .calendar-day:hover {
    transform: scale(1.1);
  }
  
  .calendar-day.selected {
    animation: pulse 0.5s;
  }
  
  /* Animation for the modal */
  .ant-modal {
    animation: slideInFromRight 0.3s ease-out;
  }
  
  /* Animation for adding new time slots */
  .time-slot {
    animation: fadeIn 0.3s ease-out;
  }
  
  .add-slot-button {
    transition: all 0.3s ease;
  }
  
  .add-slot-button:hover {
    animation: bounce 0.5s;
  }
  
  /* Animate the date range selection */
  .rdrDateRangeWrapper {
    animation: fadeIn 0.5s ease-out;
  }
  
  .rdrDateDisplayItem {
    transition: all 0.3s ease;
  }
  
  .rdrDateDisplayItem:hover {
    transform: scale(1.05);
  }
  
  /* Animate the day selection in the date range picker */
  .rdrDay {
    transition: all 0.2s ease;
  }
  
  .rdrDay:hover {
    transform: scale(1.1);
  }
  
  .rdrSelected {
    animation: pulse 0.5s;
  }

  /* Add these keyframes if not already present */
@keyframes scaleUp {
    from { transform: scale(1); }
    to { transform: scale(1.1); }
  }
  
  @keyframes highlightPulse {
    0% { box-shadow: 0 0 0 0 rgba(82, 196, 26, 0.4); }
    70% { box-shadow: 0 0 0 10px rgba(82, 196, 26, 0); }
    100% { box-shadow: 0 0 0 0 rgba(82, 196, 26, 0); }
  }
  
  /* Animations for date selection */
  .rdrDay {
    transition: all 0.2s ease-out;
  }
  
  .rdrDay:not(.rdrDayPassive):hover .rdrInRange,
  .rdrDay:not(.rdrDayPassive):hover .rdrStartEdge,
  .rdrDay:not(.rdrDayPassive):hover .rdrEndEdge,
  .rdrDay:not(.rdrDayPassive):hover .rdrSelected {
    animation: scaleUp 0.2s ease-out;
    transform-origin: center;
  }
  
  .rdrDay:not(.rdrDayPassive) .rdrInRange,
  .rdrDay:not(.rdrDayPassive) .rdrStartEdge,
  .rdrDay:not(.rdrDayPassive) .rdrEndEdge,
  .rdrDay:not(.rdrDayPassive) .rdrSelected {
    transition: all 0.2s ease-out;
  }
  
  .rdrDay:not(.rdrDayPassive) .rdrStartEdge,
  .rdrDay:not(.rdrDayPassive) .rdrEndEdge,
  .rdrDay:not(.rdrDayPassive) .rdrSelected {
    animation: highlightPulse 0.5s ease-out;
  }
  
  /* Animate the selection preview */
  .rdrDayStartPreview,
  .rdrDayInPreview,
  .rdrDayEndPreview {
    transition: all 0.2s ease-out;
  }
  
  .rdrDayStartPreview:not(.rdrDayEndPreview),
  .rdrDayEndPreview:not(.rdrDayStartPreview) {
    animation: highlightPulse 0.5s ease-out;
  }
  
  /* Animate the month navigation */
  .rdrNextPrevButton {
    transition: all 0.2s ease-out;
  }
  
  .rdrNextPrevButton:hover {
    background-color: rgba(82, 196, 26, 0.1);
    transform: scale(1.1);
  }
  
  /* Animate the month and year selectors */
  .rdrMonthAndYearPickers select {
    transition: all 0.2s ease-out;
  }
  
  .rdrMonthAndYearPickers select:hover {
    background-color: rgba(82, 196, 26, 0.1);
    transform: scale(1.05);
  }
  
  /* Animate the selected range */
  .rdrInRange {
    transition: background-color 0.3s ease-out;
  }
  
  /* Animate the definition ranges if you're using them */
  .rdrStaticRange {
    transition: all 0.2s ease-out;
  }
  
  .rdrStaticRange:hover {
    background-color: rgba(82, 196, 26, 0.1);
    transform: translateX(5px);
  }
  
  /* Animate the input fields for the selected range */
  .rdrDateDisplayWrapper {
    transition: all 0.3s ease-out;
  }
  
  .rdrDateDisplayItem {
    transition: all 0.2s ease-out;
  }
  
  .rdrDateDisplayItem input {
    transition: all 0.2s ease-out;
  }
  
  .rdrDateDisplayItem input:focus {
    transform: scale(1.05);
    box-shadow: 0 0 0 2px rgba(82, 196, 26, 0.2);
  }


  

  