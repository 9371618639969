.bn-main {
  background: linear-gradient(to bottom right, #e0f7fa, #d1c4e9);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.bn-container {
  max-width: 1200px;
  width: 100%;
  padding: 20px;
  margin: auto;
}

.bn-invoice-card {
  background-color: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  overflow: hidden;
}

.bn-invoice-header {
  background: linear-gradient(to right, #512da8, #303f9f);
  color: white;
  padding: 20px;
  text-align: center;
}

.bn-invoice-title {
  font-size: 32px;
  margin: 0;
}

.bn-invoice-subtitle {
  font-size: 16px;
  opacity: 0.8;
}

.bn-invoice-body {
  padding: 30px;
}

.bn-details-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-bottom: 20px;
}

.bn-detail-item {
  font-size: 14px;
}

.bn-detail-label {
  font-weight: 600;
  color: #616161;
}

.bn-order-section {
  border-top: 1px solid #eee;
  padding-top: 20px;
  margin-bottom: 20px;
}

.bn-order-status {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
}

.bn-status-completed {
  color: #388e3c;
}

.bn-status-failed {
  color: #d32f2f;
}

.bn-status-icon {
  margin-right: 10px;
}

.bn-thankyou-message {
  background: linear-gradient(to right, #e8f6ea, #e7f6ea);
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.bn-thankyou-text {
  font-size: 24px;
  font-weight: 600;
}

.bn-username {
  color: #309f5b;
}

.bn-item-text {
  font-size: 18px;
}

.bn-item-name {
  font-weight: 600;
}

.bn-order-details {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.bn-order-detail {
  background-color: #f5f5f5;
  padding: 15px;
  border-radius: 8px;
}

.bn-address-detail .bn-address-details {
  margin-top: 10px;
  padding-left: 15px;
}

.bn-address-item {
  margin-bottom: 5px;
}

.bn-status-description {
  text-align: center;
  font-size: 14px;
  color: #616161;
  margin-top: 20px;
}

.bn-action-buttons {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.bn-btn {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background 0.3s ease;
}

.bn-btn-primary {
  background-color: #2da839;
  color: white;
  margin-right: 10px;
}

.bn-btn-primary:hover {
  background-color: #309f43;
}

.bn-btn-secondary {
  background-color: #e0e0e0;
  color: #309f51;
}

.bn-btn-secondary:hover {
  background-color: #bdbdbd;
}

.bn-btn-icon {
  margin-right: 10px;
}

.bn-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.bn-loader-img {
  width: 50px;
  height: 50px;
}

.bn-no-order-text {
  text-align: center;
  font-size: 20px;
  color: #757575;
}